import {useCallback, useEffect, useMemo, useState} from 'react';
import qs from 'query-string';

import styles from 'styles/pages/DevDeepLinkPage.module.scss';
import classNames from 'classnames';
import {ESearchWebHost} from 'constant/Path';
import {generateUrl, getBridgeLink} from 'utils/url';
import {Paths} from 'constant/RoutePath';
import {EAppRequestMode, EPlaceCategoryType} from 'types/App';
import {DEFAULT_VALID_CATEGORY_LINK} from 'constant/PlaceCategory';
import {EAdditionalInfo, ECategoryType} from 'ducks/tplacehome/types';

const POI_ID_MAP = [
  {title: '티맵모빌리티', value: '10243691'},
  {title: 'SKT타워', value: '1128603'},
  {title: '여의도자이공인', value: '1935582'},
];

const POI_KEY_MAP = [
  {title: '티맵모빌리티', value: '1024369101'},
  {title: 'SKT타워', value: '112860300'},
  {title: '여의도자이공인', value: '1935582'},
];

const SEARCH_QUERIES = [
  'skt타워',
  '맛집',
  '티맵모빌리티',
  '명동2가 1-19',
  '우동 1388',
  '명절병원',
  '투표소',
  '사전투표소',
];

const getDeepLink = (host, query) => {
  return qs.stringifyUrl({
    url: `tmap://${host}`,
    query: {
      ...query,
      tailParam: JSON.stringify(query.tailParam),
    },
  });
};

const copyText = (text, enableConfirm = true) => {
  window.navigator.clipboard.writeText(text).then(() => {
    if (enableConfirm) {
      if (window.confirm('clipboard 에 복사되었습니다. 해당 경로로 바로 이동할까요?')) {
        window.location.href = text;
      }
    } else {
      window.alert('복사가 완료되었습니다.');
    }
  });
};

const CopyButton = ({landingUrl}) => {
  return (
    <p className={styles.preview}>
      {landingUrl}
      <button
        className={styles.copy_button}
        onClick={(e) => {
          e.preventDefault();
          copyText(landingUrl);
        }}
      >
        복사
      </button>
    </p>
  );
};

const LinkMap = ({meta, data, isBridge, tailParam = {}}) => {
  const preview = useMemo(() => {
    let query = {};

    if (typeof meta.paramKey === 'string') {
      query = {[meta.paramKey]: `[${meta.paramKey}]`};
    } else if (Array.isArray(meta.paramKey)) {
      query = meta.paramKey.reduce((prev, cur) => {
        return {...prev, [cur]: `[${cur}]`};
      }, {});
    }
    const deepLink = getDeepLink(meta.host, {...query, tailParam});
    const bridgeLink = getBridgeLink(meta.host, {...query, tailParam});

    return {deepLink, bridgeLink};
  }, [meta, tailParam]);

  const [landingUrl, setLandingUrl] = useState<string>();

  const generateLandingLink = useCallback(
    (item) => {
      let query = {};

      if (typeof meta.paramKey === 'string') {
        query = {[meta.paramKey]: item.value};
      } else if (Array.isArray(meta.paramKey)) {
        query = meta.paramKey.reduce((prev, cur) => {
          return {...prev, [cur]: item[cur]};
        }, {});
      }

      return isBridge
        ? getBridgeLink(meta.host, {...query, tailParam})
        : getDeepLink(meta.host, {...query, tailParam});
    },
    [isBridge, meta, tailParam]
  );

  useEffect(() => {
    return () => {
      setLandingUrl('');
    };
  }, [isBridge]);

  return (
    <div className={styles.link_map}>
      <h3>{meta.header}</h3>
      <p className={styles.preview}>
        {decodeURIComponent(isBridge ? preview.bridgeLink : preview.deepLink)}
      </p>
      {landingUrl && <CopyButton landingUrl={landingUrl} />}

      <div className={styles.link_wrap}>
        {data.map(({title, ...n}, idx) => {
          const url = generateLandingLink(n);
          return (
            <button
              key={`${meta.header}-${idx}`}
              className={classNames({
                [styles.none]: !!n.broken,
                [styles.is_selected]: url === landingUrl,
              })}
              onClick={() => setLandingUrl(url)}
            >
              <em>{n.value || Object.values(n).join(',')}</em>
              {title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const TailParam = ({tailParam, setTailParam, keyGuide = 'log_all_referrer', valueGuide = ''}) => {
  const [keyValue, setKeyValue] = useState(['', '']);

  return (
    <>
      <div>
        <p>{JSON.stringify(tailParam)}</p>
        <p>
          key :{' '}
          <input
            value={keyValue[0]}
            onChange={(e) => {
              setKeyValue((kv) => [e.target.value, kv[1]]);
            }}
          />
          &nbsp;
          {keyGuide && <span>ex. {keyGuide}</span>}
        </p>
        <p>
          value :{' '}
          <input
            value={keyValue[1]}
            onChange={(e) => {
              setKeyValue((kv) => [kv[0], e.target.value]);
            }}
          />
          &nbsp;
          {valueGuide && <span>ex. {valueGuide}</span>}
        </p>
        <button
          onClick={() => {
            keyValue[0] &&
              keyValue[1] &&
              setTailParam((prev) => ({...prev, [keyValue[0]]: keyValue[1]}));
          }}
        >
          추가
        </button>
        <button
          onClick={() => {
            setTailParam({});
            setKeyValue(['', '']);
          }}
        >
          초기화
        </button>
      </div>
    </>
  );
};

const LinkList = ({isBridge}) => {
  const [tailParam, setTailParam] = useState({});

  return (
    <>
      <h2>tailParam 생성</h2>
      <TailParam tailParam={tailParam} setTailParam={setTailParam} />
      <LinkMap
        meta={{header: '어디갈까 메인', host: 'nearby'}}
        data={[]}
        isBridge={isBridge}
        tailParam={tailParam}
      />
      <CopyButton
        landingUrl={
          isBridge ? getBridgeLink('nearby', {tailParam}) : getDeepLink('nearby', {tailParam})
        }
      />

      <LinkMap
        meta={{header: '어디갈까 메인 > 상단 카테고리', host: 'nearby', paramKey: 'reqKey'}}
        data={DEFAULT_VALID_CATEGORY_LINK.map((d) => ({
          title: d.label,
          value: d.type,
        }))}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '상세 - poiid', host: 'poi-detail', paramKey: 'poiid'}}
        data={POI_ID_MAP}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '상세 = pkey', host: 'poi-detail', paramKey: 'pkey'}}
        data={POI_KEY_MAP}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '검색진입', host: 'search', paramKey: 'name'}}
        data={SEARCH_QUERIES.map((q) => ({title: q, value: q}))}
        isBridge={isBridge}
        tailParam={tailParam}
      />
    </>
  );
};

const HOST_LIST = [ESearchWebHost.PROD_DOMAIN, ESearchWebHost.STAGE_DOMAIN];
const GUIDE_LIST = [
  'search_home',
  'navigation',
  'public_transportation',
  'place_tab',
  'ev_panel',
  'instagram_inflow',
];

const KEY_GUIDE_LIST = [
  ...Object.values(EPlaceCategoryType).filter((v) => !v.startsWith('SS_')),
  'SS_WATER_PLAY',
  'SS_CHERRY_BLOSSOM',
  'AD_MCDONALD',
  'SS_HOLIDAY_PARK',
];

const QuickSearch = () => {
  const [referrer, setReferrer] = useState('');
  const [reqKey, setReqKey] = useState('');
  const [result, setResult] = useState<string[]>([]);

  return (
    <div className={styles.quick_search_wrap}>
      <div>
        log_all_referrer :
        <input placeholder="navigation" onChange={(e) => setReferrer(e.target.value)} />
        <div className={styles.desc}>
          {GUIDE_LIST.map((g) => (
            <span key={g}>{g}</span>
          ))}
        </div>
      </div>
      <div>
        reqKey :
        <input placeholder="SS_WATER_PLAY" onChange={(e) => setReqKey(e.target.value)} />
        <div className={styles.desc}>
          {KEY_GUIDE_LIST.map((g) => (
            <span key={g}>{g}</span>
          ))}
        </div>
      </div>
      <button
        onClick={() => {
          const params = referrer
            ? {
                log_all_referrer: referrer,
              }
            : {};

          const g = (domain, reqMode, p?, encode = false) =>
            generateUrl(`${domain}${Paths.PlaceCategory}`, {
              type: reqKey,
              reqMode,
              ...(p
                ? {tailParam: encode ? JSON.stringify(p) : encodeURIComponent(JSON.stringify(p))}
                : {}),
            });

          const deeplink = getDeepLink('nearby', {
            reqKey,
            tailParam: params,
          });

          const bridgeLink = getBridgeLink('nearby', {
            reqKey,
            tailParam: params,
          });

          const fromNaviLink = HOST_LIST.reduce((arr, domain) => {
            return [
              ...arr,
              ' ',
              g(domain, EAppRequestMode.MAIN),
              g(domain, EAppRequestMode.MAIN, {
                log_all_referrer: 'navigation',
              }),
              g(
                domain,
                EAppRequestMode.MAIN,
                {
                  log_all_referrer: 'navigation',
                },
                true
              ),
            ] as any;
          }, []);

          const fromPublicTrans = HOST_LIST.reduce((arr, domain) => {
            return [
              ...arr,
              ' ',
              g(domain, EAppRequestMode.PUBLIC_TRANSPORT),
              g(domain, EAppRequestMode.PUBLIC_TRANSPORT, {
                log_all_referrer: 'public_transportation',
              }),
              g(
                domain,
                EAppRequestMode.PUBLIC_TRANSPORT,
                {
                  log_all_referrer: 'public_transportation',
                },
                true
              ),
            ] as any;
          }, []);

          setResult([
            'deeplink',
            deeplink,
            '브릿지 링크',
            bridgeLink,
            '내비 퀵서치 등록',
            ...fromNaviLink,
            '대중교통 퀵서치 등록',
            ...fromPublicTrans,
          ]);
        }}
      >
        생성
      </button>

      <div className={styles.result_wrap}>
        {result.map((r) =>
          r.includes('://') ? (
            <span key={r} className={styles.link} onClick={() => copyText(r, false)}>
              {r}
            </span>
          ) : (
            <>
              <br />
              <span>{r}</span>
            </>
          )
        )}
      </div>
    </div>
  );
};

const SCHEME_TPLACE_DISCOVERY = 'tmap://nearby?tailParam=%7B%22current_tab%22%3A%22discovery%22%7D';
const additionalInfo = Object.values(EAdditionalInfo).map((v) => v.toLocaleLowerCase());
const categoryType = Object.values(ECategoryType).map((v) => v.toLocaleLowerCase());
const valueGuide = [...additionalInfo, ...categoryType].join(', ');
const filterGuide = [additionalInfo[0], categoryType[0]].join(', ');
const TPlace = () => {
  const [nearbyTailParam, setNearbyTailParam] = useState({});
  const nearbyUrl = `tmap://nearby?tailParam=${encodeURIComponent(
    JSON.stringify(nearbyTailParam)
  )}`;

  const [tRankTailParam, setTRankTailParam] = useState({});
  const tRankUrl = `tmap://place?productid=nearby&pageid=RANKING&extra=${encodeURIComponent(
    JSON.stringify(tRankTailParam)
  )}`;

  return (
    <div>
      <div className={styles.link_map}>
        <h3>[어디갈까] 발견탭 랜딩</h3>
        <span className={styles.link} onClick={() => copyText(SCHEME_TPLACE_DISCOVERY, false)}>
          {SCHEME_TPLACE_DISCOVERY}
        </span>
      </div>

      <div className={styles.link_map}>
        <h3>[어디갈까] 내주변 필터 on 랜딩</h3>
        <div className={styles.guide}>
          key에는 filter 를 넣고, value 에는 필터링할 값을 콤마로 연결해주세요
        </div>
        <div className={styles.guide}>ex) key: filter, value: {filterGuide}</div>
        <div className={styles.tail_param_wrap}>
          <TailParam
            tailParam={nearbyTailParam}
            setTailParam={setNearbyTailParam}
            keyGuide="filter"
            valueGuide={valueGuide}
          />
        </div>
        <span className={styles.link} onClick={() => copyText(nearbyUrl, false)}>
          {nearbyUrl}
        </span>
      </div>

      {/* tmap://place?productid=nearby&pageid=RANKING&extra=%7B%22centerLat%22%3A35.58525325963264%2C%22centerLon%22%3A127.31269612084225%7D */}
      <div className={styles.link_map}>
        <h3>[티맵랭킹] 위치지정, 필터 on 랜딩</h3>
        <div className={styles.guide}>1. 위치지정 : centerLon, centerLat</div>
        <div className={styles.guide}>
          2. 필터 on : key에는 filter 를 넣고, value 에는 필터링할 값을 콤마로 연결해주세요
        </div>
        <div className={styles.guide}>ex) key: filter, value: {filterGuide}</div>
        <div className={styles.tail_param_wrap}>
          <TailParam
            tailParam={tRankTailParam}
            setTailParam={setTRankTailParam}
            keyGuide="filter"
            valueGuide={valueGuide}
          />
        </div>
        <span className={styles.link} onClick={() => copyText(tRankUrl, false)}>
          {tRankUrl}
        </span>
      </div>
    </div>
  );
};

enum ETab {
  BRIDGE = 'bridge',
  DEEP_LINK = 'deep_link',
  QUICK_SEARCH = 'quick_search',
  TPLACE = 'tplace',
}

const TAB_LIST = [
  {type: ETab.BRIDGE, title: '브릿지'},
  {type: ETab.DEEP_LINK, title: '딥링크'},
  {type: ETab.QUICK_SEARCH, title: '퀵서치'},
  {type: ETab.TPLACE, title: '어디갈까/티맵랭킹'},
];

const DevDeepLinkPage = () => {
  const [tab, setTab] = useState<ETab>(ETab.BRIDGE);
  const isBridge = tab === ETab.BRIDGE;

  return (
    <div className={styles.wrap}>
      <h1>DeepLink</h1>
      <div>
        {TAB_LIST.map((t) => (
          <button
            key={t.type}
            className={classNames({
              [styles.is_selected]: t.type === tab,
            })}
            onClick={() => setTab(t.type)}
          >
            {t.title} 링크 생성
          </button>
        ))}
      </div>

      <br />
      <div className={styles.notice}>
        ► ios 퀵서치는 tailParam 이 두 번 인코딩 돼야 제대로 동작하므로 두 번 인코딩 된 것을
        사용하시길 바랍니다.
      </div>
      <div className={styles.notice}>
        ► log_all_referrer 를 정의하면 어디로부터 유입됐는지 로깅할 수 있습니다. 값은 자유롭게 정의
        가능합니다.
      </div>
      <br />

      {tab === ETab.QUICK_SEARCH ? (
        <QuickSearch />
      ) : tab === ETab.TPLACE ? (
        <TPlace />
      ) : (
        <LinkList isBridge={isBridge} />
      )}
    </div>
  );
};

export default DevDeepLinkPage;
