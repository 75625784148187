import {useCallback, useEffect, useState} from 'react';
import InView from 'react-intersection-observer';
import classNames from 'classnames';
import Thumbnail from 'components/Thumbnail';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {TFestivalItem} from 'ducks/searchRecommend/types';
import {sendSearchClickLog} from 'utils/logManager';
import {TAddressItem} from 'types/Search';
import {useAppSelector} from 'ducks/hooks';
import {EImageSize} from 'types/Image';
import {getImageUrl} from 'utils/url';

import {ReactComponent as IconDotBetween} from 'resource/images/ico_dot_between.svg';
import {IcArrowRightBold} from 'components/@tmds/icons/v1.2/IcArrowRightBold';

import s from 'styles/components/search/SearchRecommendFestival.module.scss';

type TProps = {
  data: TFestivalItem[] | undefined;
  region?: TAddressItem;
  loaded: boolean;
  isVerticalType?: boolean;
};

const SearchRecommendFestivalList = ({data, region, loaded, isVerticalType}: TProps) => {
  const {reverseGeoInfo} = useAppSelector((state) => ({
    reverseGeoInfo: state.searchRecommend.reverseGeoInfo,
  }));

  const [list, setList] = useState<TFestivalItem[]>();

  const {moveToDetail} = useMoveToTarget();

  const handleClick = useCallback(
    (index: number, item: TFestivalItem) => {
      sendSearchClickLog('tap.festival_poi', {
        index,
        poiname: item.name,
        pkey: item.pkey,
        poi_region: `${item.areaDepth1Name} ${item.areaDepth2Name}`,
        regionfilter: isVerticalType ? region?.areaName : undefined,
        region_1D: reverseGeoInfo.regionName1,
        region_2D: reverseGeoInfo.regionName2,
        region_3D: reverseGeoInfo.regionName3,
      });
      moveToDetail(item);
    },
    [region, reverseGeoInfo, isVerticalType, moveToDetail]
  );

  useEffect(() => {
    if (loaded) {
      setList(data);
    }
  }, [loaded, data]);

  if (list === undefined) {
    return null;
  }
  return (
    <>
      {list.length === 0 && isVerticalType ? (
        <p className={s.no_data}>진행중인 축제가 없습니다.</p>
      ) : (
        <ul className={classNames(s.wrap, {[s.vertical]: isVerticalType})}>
          {list.map((item, index) => {
            return (
              <InView as="li" key={`${item.poiId}_${index}`} className={s.cell} threshold={0.5}>
                <button type="button" className={s.item} onClick={() => handleClick(index, item)}>
                  <Thumbnail
                    imgSrc={getImageUrl(item.imageUrl || '', EImageSize.THUMBNAIL)}
                    width={isVerticalType ? 68 : '100%'}
                    height={isVerticalType ? 68 : 114}
                  />
                  <div className={s.info}>
                    <em className={s.location}>
                      {item.areaDepth1Name} {item.areaDepth2Name}
                    </em>
                    <strong className={s.name}>{item.name}</strong>
                    {item.date && (
                      <span className={s.date}>
                        <IconDotBetween className={s.dot} />
                        {item.date}
                      </span>
                    )}
                  </div>
                  <IcArrowRightBold width={20} height={20} color={'gray500'} className={s.ico} />
                </button>
              </InView>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default SearchRecommendFestivalList;
