export enum EImagePosition {
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export enum EImageSize {
  SMALL = 1,
  BIG = 2,
  THUMBNAIL = 'T',
}
