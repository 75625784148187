import {createContext, useContext, useState} from 'react';

type TAdBannerContextValue = {
  isAdTop: boolean;
  setIsAdTop: (value: boolean) => void;
};

const AdBannerContext = createContext<TAdBannerContextValue>({
  isAdTop: false,
  setIsAdTop: () => {},
});

export const useAdBanner = () => useContext(AdBannerContext);

export const AdBannerProvider = ({children}: {children: React.ReactNode}) => {
  const [isAdTop, setIsAdTop] = useState(false);

  return (
    <AdBannerContext.Provider
      value={{
        isAdTop,
        setIsAdTop,
      }}
    >
      {children}
    </AdBannerContext.Provider>
  );
};
