/**
 * 커스텀 이벤트 발신/수신용
 */
import {TinyEmitter} from 'tiny-emitter';

export enum ECustomEvent {
  POI_FAVORITE_CHANGED = 'POI_FAVORITE_CHANGED',
  SAVE_POI_MARKER_RELOADED = 'SAVE_POI_MARKER_RELOADED',
}

export const customEventEmitter = new TinyEmitter();
