/**
 * [임시 확인용] 저장 QA 끝나면 지울 것 (24.12.10)
 */
import Vsm from '@vsm/vsm';
import {isProdEnv, isRtgEnv} from 'constant/Env';
import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {useAppSelector} from 'ducks/hooks';
import {useEffect, useState} from 'react';

const BuildInfoTempZoom = () => {
  const storeState = useAppSelector((state) => state);
  const [zoom, setZoom] = useState(0);
  const {map} = useVSMInterfaceConsumer();
  useEffect(() => {
    const onZoomEnd = () => {
      setZoom(map?.getCamera().getZoom() || 0);
    };
    if (map) {
      map.on(Vsm.Map.EventNames.ZoomEnd, onZoomEnd);
      return () => {
        map.off(Vsm.Map.EventNames.ZoomEnd, onZoomEnd);
      };
    }
  }, [map]);
  if (isProdEnv || isRtgEnv) {
    return null;
  }
  return (
    <div
      style={{
        fontSize: '10px',
        position: 'absolute',
        left: '10px',
        bottom: '36px',
        zIndex: 1000,
        color: 'red',
        backgroundColor: 'white',
        pointerEvents: 'none',
      }}
    >
      {`ZOOM: ${(zoom && zoom.toFixed(3)) || ''}`}
      <br />
      {storeState.remote.saveMapLevelClustering.cluster.map((n, i) =>
        (i + 1) % 5 === 0 ? `${n} ` : `${n}`
      )}
      {' - '}
      {storeState.remote.saveMapLevelClustering.minFavCount}
    </div>
  );
};

export default BuildInfoTempZoom;
