import {Fragment, useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {ECategoryType} from 'ducks/search/types';
import classNames from 'classnames';
import {ReactComponent as IconFilterOff} from 'resource/images/icon_filter_off.svg';
import {ReactComponent as IconFilterOn} from 'resource/images/icon_filter_on.svg';
import useDimmedQuery from 'hooks/useDimmedQuery';
import SearchRecommendFilterDetailPopup from './SearchRecommendFilterDetailPopup';
import {TActionId} from 'types/Log';
import useLogger from 'hooks/useLogger';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {ReactComponent as IconDivider} from 'resource/images/divider.svg';
import useSearchResult from 'hooks/useSearchResult';
import {EFilter} from 'components/FilterBottomPopup';

import s from 'styles/components/search/SearchRecommendHeader.module.scss';

const FILTER_MAP = {
  [EFilter.TMAP_FAMOUS]: {
    label: '티맵인기',
    apiParam: 'tmapFamousYn',
    category: null,
    key: EFilter.TMAP_FAMOUS,
  },
  [EFilter.OPEN_NOW]: {
    label: '영업중',
    apiParam: 'openNowYn',
    category: null,
    key: EFilter.OPEN_NOW,
  },
  [EFilter.WAITING_RESERVATION]: {
    label: '예약/웨이팅',
    apiParam: 'isWaitingReservation',
    category: null,
    key: EFilter.WAITING_RESERVATION,
  },
  [EFilter.POI_PARK]: {
    label: '주차',
    apiParam: 'poiParkYn',
    category: null,
    key: EFilter.POI_PARK,
  },
  [ECategoryType.FUDKOR]: {
    label: '한식',
    category: ECategoryType.FUDKOR,
    apiParam: null,
    key: ECategoryType.FUDKOR,
  },
  [ECategoryType.FUDCHN]: {
    label: '중식',
    category: ECategoryType.FUDCHN,
    apiParam: null,
    key: ECategoryType.FUDCHN,
  },
  [ECategoryType.FUDWES]: {
    label: '양식',
    category: ECategoryType.FUDWES,
    apiParam: null,
    key: ECategoryType.FUDWES,
  },
  [ECategoryType.FUDJPN]: {
    label: '일식',
    category: ECategoryType.FUDJPN,
    apiParam: null,
    key: ECategoryType.FUDJPN,
  },
  [ECategoryType.CAFEALL]: {
    label: '카페/디저트',
    category: ECategoryType.CAFEALL,
    apiParam: null,
    key: ECategoryType.CAFEALL,
  },
  [ECategoryType.FUDBAR]: {
    label: '술집',
    category: ECategoryType.FUDBAR,
    apiParam: null,
    key: ECategoryType.FUDBAR,
  },
};

type TProps = {
  onChange?: (filter: string, isOn: boolean) => void;
  onChangeCategory?: (category: ECategoryType[]) => void;
  onClickConfirm?: (
    filters: {[key: string]: boolean},
    categories: ECategoryType[],
    filterLog: {[key: string]: boolean}
  ) => void;
};

export type TFilterProps = {
  label: string;
  apiParam: Nullable<string>;
  category: Nullable<ECategoryType>;
  key: EFilter | ECategoryType;
};

const SearchRecommendHeader = ({onChange, onChangeCategory, onClickConfirm}: TProps) => {
  const {search, prevGuideSearchType} = useAppSelector((state) => ({
    search: state.search,
    prevGuideSearchType: state.search.prevGuideSearchType,
  }));
  const dispatch = useAppDispatch();
  const {sendClickLog} = useLogger();
  const {queries} = useParseQueryLocation();
  const {isError} = useSearchResult();
  const {tmapFamousYn, openNowYn, isWaitingReservation, poiParkYn, categories: cate} = search;

  const clickedFilterLength = useMemo(
    () =>
      [tmapFamousYn, openNowYn, isWaitingReservation, poiParkYn, ...(cate || [])].filter((v) => !!v)
        .length,
    [tmapFamousYn, openNowYn, isWaitingReservation, poiParkYn, cate]
  );

  const filterMenuDimmed = useDimmedQuery({place: 'filter_menu'});

  const handleClickMenu = useCallback(() => {
    if (isError) {
      return;
    }

    sendClickLog('tap.filter');
    filterMenuDimmed.open();
  }, [isError, filterMenuDimmed, sendClickLog]);

  const handleClickQuickFilter = useCallback(
    (label: string | ECategoryType, isOn: boolean, key: EFilter | ECategoryType) => {
      if (!label || isError) {
        return;
      }
      dispatch(
        actions.search.resetList({
          guideSearchType: prevGuideSearchType,
        })
      );

      sendClickLog(
        `tap.filter_${key}` as TActionId,
        {
          search_query: queries.searchQuery,
          status: isOn,
        },
        {includeTicketId: true}
      );

      if (!!ECategoryType[label]) {
        const categories = search.categories ?? [];

        const isNewCategory = categories?.includes(label as ECategoryType) ?? true;
        const newCategories = isNewCategory
          ? [...categories].filter((c) => c !== label)
          : [...categories, label as ECategoryType];

        dispatch(
          actions.search.updateCategories({
            ltrYn: true,
            fromRecommendedYn: true,
            categories: newCategories,
          })
        );
        onChangeCategory?.(newCategories);
        return;
      }

      // EFilter 필터 클릭시
      dispatch(
        actions.search.updateFilter({
          ltrYn: true,
          fromRecommendedYn: true,
          [label]: isOn,
        })
      );
      onChange?.(label, isOn);
    },
    [
      isError,
      onChangeCategory,
      dispatch,
      search.categories,
      onChange,
      sendClickLog,
      queries.searchQuery,
      prevGuideSearchType,
    ]
  );

  const getFilterComponent = ({label, apiParam, category, key}: TFilterProps) => {
    const isOn = apiParam
      ? !!search[apiParam]
      : !!(search.categories && category && search.categories.includes(category));
    const clickType = apiParam ?? category;
    return (
      <Fragment key={label}>
        <div
          key={label}
          className={classNames(s.filter, {
            [s.on]: isOn,
            [s.has_error]: isError,
          })}
          onClick={() => {
            handleClickQuickFilter(clickType ?? '', !isOn, key);
          }}
        >
          <span className={s.text}>{label}</span>
        </div>
        {key === EFilter.POI_PARK && (
          <div className={s.divider}>
            <IconDivider />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <div className={classNames(s.header_wrap, s.has_filter)}>
        <div className={s.filter_wrap}>
          <div
            className={classNames(s.filter, s.menu, {
              [s.on]: clickedFilterLength > 0,
              [s.has_content]: clickedFilterLength > 0,
              [s.has_error]: isError,
            })}
            onClick={handleClickMenu}
          >
            {clickedFilterLength > 0 ? (
              <IconFilterOn width={18} height={18} />
            ) : (
              <IconFilterOff width={18} height={18} />
            )}
            {clickedFilterLength > 0 && (
              <span className={s.filter_menu}>{clickedFilterLength}</span>
            )}
          </div>
          {Object.keys(FILTER_MAP).map((key) => getFilterComponent(FILTER_MAP[key]))}
        </div>
      </div>

      <SearchRecommendFilterDetailPopup
        isOpen={filterMenuDimmed.isOpen}
        close={filterMenuDimmed.close}
        filterMap={FILTER_MAP}
        onClickConfirm={onClickConfirm}
        onClickReset={() => {
          sendClickLog('tap.reset');
        }}
      />
    </>
  );
};

export default SearchRecommendHeader;
