import {useCallback, useMemo} from 'react';
import {ERecentItemType, TRecentItem} from 'types/Search';
import ua from 'utils/uaParser';
import SearchListIcon from 'components/search/SearchListIcon';
import CheckboxIcon from 'components/CheckboxIcon';

import s from 'styles/components/search/SearchRecentEditItem.module.scss';

type TProps = {
  data: TRecentItem;
  isSelect?: boolean;
  onSelect?: (id: string) => void;
};

const RECENT_ITEM_TYPE: Record<ERecentItemType, string> = {
  [ERecentItemType.POI]: '길찾기',
  [ERecentItemType.QUERY]: '검색',
  [ERecentItemType.PUBLIC_POI]: '대중교통 경로',
  [ERecentItemType.HOME]: '즐겨찾기 집',
  [ERecentItemType.OFFICE]: '즐겨찾기 회사',
  [ERecentItemType.FAVORITE]: '즐겨찾기',
  [ERecentItemType.FAVORITE_STATION]: '대중교통 정류장 즐겨찾기',
  [ERecentItemType.FAVORITE_SUBWAY]: '대중교통 역 즐겨찾기',
  [ERecentItemType.BUS_STATION]: '정류장',
  [ERecentItemType.SUBWAY]: '지하철역',
};

const SearchRecentEditItem = ({data, isSelect, onSelect}: TProps) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      // ios 에서 빠르게 클릭 했을 때 이전 클릭 이벤트가 발생하는 문제 해결
      // webkit 에서 double click 을 판단하기 위해 click event delay 350ms 를 적용
      // 그 사이 클릭이 한 번 더 발생할 경우 double click 으로 판단
      // https://medium.com/@mihai/disabling-the-click-delay-in-uiwebview-5cd6b18a8c19
      // https://tmobi.atlassian.net/browse/CLIENTQA-5555
      if (ua.isIos) {
        if (e.detail === 1) {
          onSelect?.(data.id);
        }
      } else {
        onSelect?.(data.id);
      }
    },
    [data.id, onSelect]
  );

  const itemLabel = useMemo(() => {
    return `${data.name} ${RECENT_ITEM_TYPE[data.type]}`;
  }, [data.name, data.type]);

  return (
    <li
      className={s.search_recent_edit_item}
      onClick={handleClick}
      role={'checkbox'}
      aria-label={itemLabel}
      aria-checked={isSelect}
    >
      <SearchListIcon srcType={data.type} className={s.icon} />
      <div className={s.name}>{data.name}</div>

      <CheckboxIcon on={isSelect} />
    </li>
  );
};

export default SearchRecentEditItem;
