import {useCallback, useEffect, useState} from 'react';
import {TAdsItem, TTMapAdUnitMaterial} from 'hooks/useTMapAds';
import useLogger from 'hooks/useLogger';
import {EAdCode} from 'constant/Ads';
import InView from 'react-intersection-observer';
import useThrottle from 'hooks/useThrottle';
import {TActionId} from 'types/Log';

import s from 'styles/components/ranking/RankingBanner.module.scss';

type TProps = {
  data: TAdsItem;
  onClick: () => void;
  onShow: (url: string, option: any) => void;
};

type TBannerInfo = Record<string, TTMapAdUnitMaterial>;

const RankingBanner = ({data, onClick, onShow}: TProps) => {
  const [bannerInfo, setBannerInfo] = useState<Nullable<TBannerInfo>>(null);
  const {sendClickLog} = useLogger();
  const ableToRun = useThrottle();

  const sendAdLog = useCallback(
    (actionId: TActionId) => {
      if (!data) {
        return;
      }

      sendClickLog(actionId, {
        index: EAdCode.RANKING_GO_WHERE_BANNER,
        unit: data.originAd.adId,
        ad_source: 'internal',
        url: data.landingUrl,
      });
    },
    [sendClickLog, data]
  );

  const sendImpressionLog = useCallback(() => {
    data && onShow(data.logs.vimp, {updateId: true});
    sendAdLog('view.ad');
  }, [sendAdLog]);

  const handleClick = useCallback(() => {
    if (!ableToRun()) {
      return;
    }

    sendAdLog('tap.ad');
    onClick();
  }, [ableToRun, onClick, sendAdLog]);

  useEffect(() => {
    if (!data.originAd.materials.length) {
      return;
    }

    const result = data.originAd.materials.reduce((acc, item) => {
      const key = item.subType || item.type;

      acc[key] = item;
      return acc;
    }, {});
    setBannerInfo(result);
  }, [data]);

  return (
    <>
      {bannerInfo ? (
        <InView
          onChange={(isVisible) => {
            isVisible && sendImpressionLog();
          }}
        >
          <div
            className={s.wrap}
            onClick={() => {
              handleClick();
            }}
          >
            <div className={s.text_wrap}>
              <div className={s.text}>{bannerInfo?.TEXT.text}</div>
              <div className={s.sub_text}>{bannerInfo?.subtext.text}</div>
            </div>

            <div className={s.image_wrap}>
              <img
                className={s.banner_image}
                src={bannerInfo?.image.url}
                alt="광고 배너"
                width={68}
                height={60}
                // 이미지 사이즈 고정
              />
            </div>
          </div>
        </InView>
      ) : null}
    </>
  );
};

export default RankingBanner;
