import {useCallback, useEffect, useMemo, useRef} from 'react';

import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import useMap from 'hooks/useMap';
import useCurrentPosition from 'hooks/useCurrentPosition';
import {useOnce} from 'hooks/useOnce';
import useMapOffset from 'hooks/useMapOffset';

import {EMarkerType, TLonLat} from 'types/Map';

import {SEARCH_DEFAULT_ZOOM, SEARCH_MAP_LIMIT_OPTION} from 'constant/Map';
import {MAX_MARKER_HEIGHT, MAX_MARKER_WIDTH} from 'constant/Size';

import {convertToLonLat} from 'utils/map';
import {sendSearchClickLog} from 'utils/logManager';

import VSMMap from 'components/VSMMap';
import VSMMarker from 'components/VSMMarker';
import MapCenterMarker from 'components/MapCenterMarker';

import {ImageMarker} from 'components/ImageMarker';
import s from 'styles/components/PickOnMap.module.scss';

const BOTTOM_ADDRESS_HEIGHT = 130;
const LANDSCAPE_CALLOUT_WIDTH = 360;
const PICK_ON_MAP_HEADER_HEIGHT = 124;

const MAP_OFFSET = {
  x: 0,
  y: -MAX_MARKER_HEIGHT,
};

type TProps = {
  bottomAddressHeight?: number;
  onClickMap?: () => void;
  onClickPoi?: () => void;
  initCenter?: TLonLat;
};

const PickOnMap = ({
  bottomAddressHeight = BOTTOM_ADDRESS_HEIGHT,
  onClickMap,
  onClickPoi,
  initCenter,
}: TProps) => {
  const dispatch = useAppDispatch();

  const {
    isInitialized,
    getOffsetCenter,
    getFixedZoomLevel,
    moveCoordIntoView,
    moveToCenter,
    getCoordScreenPoint,
    resize,
  } = useMap();
  const {currentPosition} = useCurrentPosition();
  const {centerOffset} = useMapOffset({offsetTop: PICK_ON_MAP_HEADER_HEIGHT});
  const refCenter = useRef<TLonLat>();

  const {mapFontSize, mapStyle, pickOnMapCalloutInfo, mapContext, isLandscape, windowSize} =
    useAppSelector((state) => ({
      mapFontSize: state.map.fontSize,
      mapStyle: state.map.style,
      pickOnMapCalloutInfo: state.userInteraction.pickOnMapCalloutInfo,
      mapContext: state.userInfo.mapContext,
      isLandscape: state.layout.appSize.isLandscape,
      windowSize: state.layout.windowSize,
    }));

  const boundsPadding = useMemo(
    () => ({
      top: MAX_MARKER_HEIGHT,
      right: MAX_MARKER_WIDTH,
      bottom: isLandscape ? MAX_MARKER_WIDTH : bottomAddressHeight + MAX_MARKER_HEIGHT,
      left: MAX_MARKER_WIDTH,
    }),
    [isLandscape, bottomAddressHeight]
  );

  const moveToCenterWithOffset = useCallback(
    ({lon, lat}) => {
      window.setTimeout(() => {
        moveToCenter(
          {lon, lat},
          {
            animate: false,
            offset: MAP_OFFSET,
          }
        );
      }, 0);
    },
    [moveToCenter]
  );

  const handleMoveEnd = useCallback(() => {
    const center = convertToLonLat(getOffsetCenter(MAP_OFFSET));

    if (center?.lat && center?.lon) {
      dispatch(actions.map.setNowCenter(center));
    }
    refCenter.current = center;
    dispatch(actions.map.setZoom(getFixedZoomLevel() || 0));
  }, [getOffsetCenter, dispatch, getFixedZoomLevel]);

  const handleLongPressMap = useCallback(
    (e) => {
      const {lon, lat} = e.point;

      onClickPoi?.();
      moveCoordIntoView(
        {lon, lat},
        {
          ...boundsPadding,
          right: boundsPadding.right + (isLandscape ? LANDSCAPE_CALLOUT_WIDTH : 0),
        }
      );
      dispatch(
        actions.userInteraction.setPickOnMapCalloutInfo({
          lon,
          lat,
          markerType: EMarkerType.ACTIVE,
          poiId: '',
          pkey: '',
          stationSktId: '',
          publicTransportType: undefined,
          favId: '',
        })
      );

      sendSearchClickLog('longtap.map');
    },
    [dispatch, moveCoordIntoView, boundsPadding, isLandscape, onClickPoi]
  );

  const handleClickMap = useCallback(() => {
    onClickMap?.();
    dispatch(actions.userInteraction.clearPickOnMapCalloutInfo());
  }, [dispatch, onClickMap]);

  const handleClickPoi = useCallback(
    (poiFeature) => {
      const {properties, geometry} = poiFeature;
      const [lon, lat] = geometry.coordinates;
      const publicTransportType = properties?.publicTransportType;

      onClickPoi?.();
      moveCoordIntoView(
        {lon, lat},
        {
          ...boundsPadding,
          right: boundsPadding.right + (isLandscape ? LANDSCAPE_CALLOUT_WIDTH : 0),
        }
      );
      dispatch(
        actions.userInteraction.setPickOnMapCalloutInfo({
          lon,
          lat,
          poiId: properties?.id,
          pkey: properties?.pkey,
          stationSktId: properties?.stationSktId,
          customName: properties?.customName || '',
          markerType: EMarkerType.ACTIVE,
          publicTransportType,
          publicTransportName: publicTransportType ? properties.name1 : '',
          favId: '',
        })
      );
      sendSearchClickLog('tap.map_poi');
    },
    [dispatch, moveCoordIntoView, boundsPadding, isLandscape, onClickPoi]
  );

  useOnce(isInitialized && initCenter?.lat && initCenter?.lon, () => {
    // landscape 변경 완료 이벤트가 없어 setTimeout으로 처리
    if (initCenter?.lat && initCenter?.lon) {
      moveToCenterWithOffset(initCenter);
    }
  });

  useEffect(() => {
    if (!initCenter) {
      // 초기 중심점 로딩
      handleMoveEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  useEffect(() => {
    if (currentPosition) {
      moveToCenterWithOffset(currentPosition);
    }
  }, [currentPosition]);

  useEffect(() => {
    window.setTimeout(() => {
      resize();
    }, 0);
  }, [isLandscape]);

  useEffect(() => {
    const center = refCenter.current;

    if (center && centerOffset && pickOnMapCalloutInfo) {
      if (isLandscape) {
        const point = getCoordScreenPoint(pickOnMapCalloutInfo);

        if (point) {
          const isUpper = point.y < windowSize.height / 2;

          moveToCenter(center, {
            animate: false,
            offset: {
              x: centerOffset.x,
              y:
                (windowSize.height - point.y) / 2 +
                (isUpper ? centerOffset.y : -centerOffset.y + MAX_MARKER_HEIGHT),
            },
          });
        }
      } else {
        moveToCenter(center, {
          animate: false,
          offset: {
            y: 0,
            x: 0,
          },
        });
      }
    }
  }, [isLandscape]);

  useEffect(() => {
    dispatch(actions.map.setCenterOffset(MAP_OFFSET));

    return () => {
      dispatch(actions.map.setCenterOffset({x: 0, y: 0}));
    };
  }, []);

  return (
    <div className={s.map_wrap} role="presentation" aria-hidden="true">
      <VSMMap
        initOptions={
          initCenter && initCenter.lon && initCenter.lat
            ? {
                center: {
                  lng: initCenter.lon,
                  lat: initCenter.lat,
                },
                zoom: mapContext?.zoom || SEARCH_DEFAULT_ZOOM,
                bearing: mapContext?.rotate || 0,
                pitch: mapContext?.tilt || 0,
                ...SEARCH_MAP_LIMIT_OPTION,
              }
            : {}
        }
        fontSize={mapFontSize}
        mapStyle={mapStyle}
        onMoveEnd={handleMoveEnd}
        onLongPressMap={handleLongPressMap}
        onClick={handleClickMap}
        onClickPoi={handleClickPoi}
        onDragStart={() => sendSearchClickLog('panning.map')}
        onZoomEnd={(e) =>
          e.data?.domEvent && sendSearchClickLog(e.isZoomIn ? 'pinchout.map' : 'pinchin.map')
        }
      />

      {currentPosition && (
        <VSMMarker lonLat={{lon: currentPosition.lon, lat: currentPosition.lat}} clickable={false}>
          <ImageMarker type={EMarkerType.CURRENT_POSITION} />
        </VSMMarker>
      )}

      {pickOnMapCalloutInfo?.publicTransportType === 'busstop' && (
        <VSMMarker
          lonLat={{
            lon: pickOnMapCalloutInfo.lon,
            lat: pickOnMapCalloutInfo.lat,
          }}
          anchor="bottom"
          type={EMarkerType.ACTIVE_BUS_STATION}
          {...pickOnMapCalloutInfo}
        />
      )}

      {pickOnMapCalloutInfo?.publicTransportType === 'subway' && (
        <VSMMarker
          lonLat={{
            lon: pickOnMapCalloutInfo.lon,
            lat: pickOnMapCalloutInfo.lat,
          }}
          anchor="bottom"
          type={EMarkerType.ACTIVE_SUBWAY}
          {...pickOnMapCalloutInfo}
        />
      )}

      {pickOnMapCalloutInfo ? (
        <>
          {!pickOnMapCalloutInfo?.publicTransportType ? (
            <VSMMarker
              lonLat={{
                lon: pickOnMapCalloutInfo.lon,
                lat: pickOnMapCalloutInfo.lat,
              }}
              anchor="bottom"
              type={EMarkerType.ACTIVE}
              {...pickOnMapCalloutInfo}
            />
          ) : null}
        </>
      ) : (
        <MapCenterMarker offset={MAP_OFFSET} />
      )}
    </div>
  );
};

export default PickOnMap;
