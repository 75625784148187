import {useCallback, useMemo} from 'react';
import {getApiTime} from 'utils/date';
import {APP_SERVER_NAME} from 'constant/Env';
import {v4 as uuidv4} from 'uuid';
import {useAppSelector} from 'ducks/hooks';
import {useParseQueryLocation} from './useParseQueryLocation';
import ua from 'utils/uaParser';
import {getFirstParam} from 'utils/search';
import {TLonLat} from 'types/Map';
import {TRequestSetting} from './useTMapAds';
import {ETMapBannerCode} from 'constant/Ads';

type TProps = {
  inventoryCode: ETMapBannerCode;
};

const TEMP_ADID = uuidv4();

export const useAdRequestParams = ({inventoryCode}: TProps) => {
  const {userInfo, isPortrait, nowCenter, nowCenterPosTime, sessionId} = useAppSelector(
    (state) => ({
      userInfo: state.userInfo,
      isPortrait: !state.layout.appSize.isLandscape,
      nowCenter: state.map.nowCenter,
      nowCenterPosTime: state.map.nowCenterPosTime,
      sessionId: state.userInfo.sessionId,
    })
  );
  const {originQueries} = useParseQueryLocation();

  const requestSetting: TRequestSetting = useMemo(() => {
    const originWidth = isPortrait ? window.innerWidth : window.innerHeight;
    const originHeight = isPortrait ? window.innerHeight : window.innerWidth;

    return {
      env: APP_SERVER_NAME,
      osType: ua.isInApp ? (ua.isAndroid ? 'AND' : 'IOS') : 'ETC',
      osVersion: ua.osVersion,
      appVersion: ua.tmapAppVersion || `${originQueries.tak || ''}` || '99.99.99',
      appCode: 'tmap',
      deviceId: userInfo.device.deviceId,
      carrier: userInfo.device.carrierName,
      userKey: userInfo.userKey,
      inventoryCode,
      lmt: userInfo.adId ? 1 : 0,
      adid: ua.isInApp && ua.isAndroid ? userInfo.adId : TEMP_ADID,
      idfa: ua.isInApp && ua.isIos ? userInfo.adId : '',
      idfv: ua.isInApp && ua.isIos ? userInfo.deviceServiceVendorId : '',
      language: navigator.language,
      deviceWidth: originWidth,
      deviceHeight: originHeight,
      orientation: isPortrait ? 'portrait' : 'landscape',
      sessionId,
    };
  }, [isPortrait, originQueries.tak, userInfo, sessionId, inventoryCode]);

  const getRequestParams = useCallback(
    (bannerLonLat?: TLonLat) => {
      const targetLonLat = bannerLonLat || nowCenter;

      return {
        keyword: getFirstParam(originQueries.searchQuery),
        lon: `${targetLonLat?.lon || ''}`,
        lat: `${targetLonLat?.lat || ''}`,
        posTime: getApiTime(nowCenterPosTime),
      };
    },
    [originQueries.searchQuery, nowCenter, nowCenterPosTime]
  );

  return {
    requestSetting,
    getRequestParams,
  };
};
