import {TItemTag} from 'components/ItemTagList';
import {CATEGORY_TAG_MAP, DEFAULT_TAG_LIST, TAG_PROPERTIES} from 'constant/Tag';
import {useMemo} from 'react';
import {ECategoryGroup, EEVChargeTypeCode, EPlaceTag} from 'types/App';
import {getEVStationBiImage} from 'utils/general';
import {getSafeDateFormat} from 'utils/date';
import {EDateFormat} from 'types/DateTime';

const getPriorityTagList = (categoryGroup) => {
  const priorityTagList = [...DEFAULT_TAG_LIST, ...(CATEGORY_TAG_MAP[categoryGroup] || [])].filter(
    (tag, idx, arr) => idx >= arr.lastIndexOf(tag)
  );

  return priorityTagList;
};

export const generateTagComponentList = ({categoryGroup, placeTags, special}) => {
  const priorityTagList = getPriorityTagList(categoryGroup);

  const result = (placeTags || [])
    .filter((type) => priorityTagList.includes(type))
    .map((type) => ({...(TAG_PROPERTIES[type] || {}), type}));

  if (categoryGroup === ECategoryGroup.EV_CHARGING_STATION) {
    const chargerTypes = special?.evChargerInfo?.chargerTypes || [];
    const biTags = getEVStationBiImage(special?.evChargerInfo, special?.isTesla);

    biTags.forEach((bi) => result.push({type: EPlaceTag.EV_CPO, icon: bi?.icon, label: bi?.label}));

    [
      chargerTypes.includes(EEVChargeTypeCode.DC_COMBO) && EPlaceTag.EV_DC_COMBO,
      chargerTypes.includes(EEVChargeTypeCode.DC_CHADEMO) && EPlaceTag.EV_DC_CHADEMO,
      chargerTypes.includes(EEVChargeTypeCode.AC_THREE_PHASE) && EPlaceTag.EV_AC3,
      chargerTypes.includes(EEVChargeTypeCode.AC_SINGLE_PHASE) && EPlaceTag.EV_AC_SLOW,
    ]
      .filter((v) => !!v)
      .forEach((n) => result.push({...(TAG_PROPERTIES[n as EPlaceTag] || {}), type: n}));
  }

  if (categoryGroup === ECategoryGroup.ACCOMMODATION) {
    const {isLowestPrice, isTmapOnly, tmapOnlyStartDateTime, tmapOnlyEndDateTime} =
      special?.accommodationInfo || {};

    isTmapOnly &&
      result.push({
        ...TAG_PROPERTIES[EPlaceTag.TMAP_ONLY](
          getSafeDateFormat(tmapOnlyStartDateTime, EDateFormat.MMd),
          getSafeDateFormat(tmapOnlyEndDateTime, EDateFormat.MMd)
        ),
        type: EPlaceTag.TMAP_ONLY,
      });
    isLowestPrice && result.push({...TAG_PROPERTIES[EPlaceTag.LOWEST], type: EPlaceTag.LOWEST});
  }

  if (special.advertiseInfo?.isPoiAdvertiseYn) {
    const label = special.advertiseInfo.advertiseMaterials.find(
      (material) => material.subType === 'adNotice'
    )?.text;

    if (!!label) {
      result.push({...TAG_PROPERTIES[EPlaceTag.AD_NOTICE], label, type: EPlaceTag.AD_NOTICE});
    }
  }

  const orderTagList = result.sort(
    (a, b) => priorityTagList.indexOf(a.type) - priorityTagList.indexOf(b.type)
  );

  return orderTagList;
};

export const getTagList = (placeTags, categoryGroup) => {
  if (placeTags.length > 0) {
    const priorityTagList = getPriorityTagList(categoryGroup);

    const orderTagList = placeTags
      .map((tag) => (priorityTagList.includes(tag) ? {...TAG_PROPERTIES[tag], tag} : null))
      .filter((i) => !!i)
      .sort((a, b) => priorityTagList.indexOf(a) > priorityTagList.indexOf(b));

    return orderTagList;
  }
  return [];
};

export const usePoiMainContentTag = (tagMap, {categoryGroup, placeTags, special}) => {
  const tagComponentList: Array<TItemTag> = useMemo(() => {
    return generateTagComponentList({categoryGroup, placeTags, special});
  }, [categoryGroup, placeTags, special]);

  return tagComponentList;
};
