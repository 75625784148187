import {
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
  CSSProperties,
} from 'react';
import {useHistory} from 'react-router';
import classNames from 'classnames';
import {EMapStyle} from '@lcc/tmap-inapp';

import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {TDrawerOptions} from 'types/App';
import {EDirection, EListMode, TListDrawerResult, TModeResult} from 'types/ListDrawer';
import {TLonLat} from 'types/Map';

import {DEFAULT_HANDLE_BORDER_RADIUS, TO_TOP_SHOW_SCROLL_HEIGHT} from 'constant/Size';
import {EDrawerActionId, EListActionId} from 'constant/Log';

import useListDrawer from 'hooks/useListDrawer';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import useLogger from 'hooks/useLogger';

import {DrawerProvider} from 'context/DrawerContext';

import {StorageKey} from 'constant/Storage';
import {TooltipProvider} from 'context/TooltipContext';

import DevInfo from 'components/DevInfo';
import CurrentPositionButton from 'components/CurrentPositionButton';
import PlaceRefreshButton from 'components/PlaceRefreshButton';
import {PoiCalloutPopup} from 'components/PoiCalloutPopup';
// import {TNowLandingButton} from 'components/TNowLandingButton';
import RankingLocationButton from './ranking/RankingLocationButton';
import RankingListModeButton from './ranking/RankingListModeButton';

import {ReactComponent as IconToTop} from 'resource/images/@tmds_basic/ico_arrow_tale_up.svg';

import s from 'styles/components/DrawerContainer.module.scss';
import SaveToggleButton from './SaveToggleButton';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';

const TOP_BTN_HEIGHT = 150;

type TProps = {
  mapComponent: (drawerProps: TListDrawerResult) => ReactNode;
  listHeaderComponent: ReactNode;
  listComponent: ReactNode;
  tooltipStorageKey?: StorageKey;
  onChangeListMode?: (listMode: EListMode) => void;
  onRefresh?: (refreshCenter: TLonLat) => void;
  listMode?: EListMode;
  listTopAreaPadding?: number;
  extraMargin?: number;
  list: any[];
  drawerOptions?: TDrawerOptions;
  statusBarHeight?: number;
  isLastPage?: boolean;
  isStaticRefreshLabel?: boolean;
  onScroll?: (scrollTop: number) => void;
  paddingTop?: number;
  handleBorderRadius?: number;
  handleSize?: number;
  visibleTNowLanding?: boolean;
  isPadColorWhite?: boolean;
  isHideToTop?: boolean;
  showFixedTopBtn?: boolean;
  hide?: boolean;
  setHide?: () => void;
  refreshButtonVisible?: boolean;
  fixedContentMode?: boolean;
  fixedContent?: ReactNode;
  onChangeBottomPadding?: (value: number) => void;
  onChangePositionMode?: (values: TModeResult) => void;
  renderCurrentPosition?: (p: {
    onAfterPosition?: (e: TLonLat) => void;
    disableAutoMove?: boolean;
  }) => ReactNode;
  bottomGradientVisible?: boolean;
  saveToggleButtonVisible?: boolean;
};

const DrawerContainer = memo(
  ({
    mapComponent: MapComponent,
    listHeaderComponent: ListHeader,
    listComponent: List,
    tooltipStorageKey,
    onChangeListMode,
    onRefresh,
    isStaticRefreshLabel,
    listMode,
    listTopAreaPadding,
    extraMargin = 0,
    list,
    drawerOptions,
    statusBarHeight,
    isLastPage,
    onScroll,
    paddingTop,
    handleBorderRadius = DEFAULT_HANDLE_BORDER_RADIUS,
    handleSize,
    visibleTNowLanding,
    isPadColorWhite,
    isHideToTop,
    showFixedTopBtn = false,
    hide = false,
    setHide,
    refreshButtonVisible = true,
    fixedContentMode,
    fixedContent: FixedContent,
    onChangeBottomPadding,
    onChangePositionMode,
    renderCurrentPosition,
    bottomGradientVisible,
    saveToggleButtonVisible,
  }: TProps) => {
    const refScrollList = useRef<HTMLDivElement>(null);
    const refList = useRef<HTMLDivElement>(null);

    const firstRenderFlag = useRef(false);
    const [listBottomPadding, setListBottomPadding] = useState<number>(0);
    const [forceMoveDrawer, setForceMoveDrawer] = useState<Nullable<number>>(null);
    const [showRefresh, setShowRefresh] = useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = useState<number>(0);

    const [showTopBtn, setTopBtn] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {sendClickLogWithMapView, sendClickLog} = useLogger();

    const listMoveToTop = useCallback((y) => {
      // TODO: ranking에서 스크롤 미동작 확인
      if (refScrollList.current) {
        refScrollList.current.scrollTop = y || 0;
      }
    }, []);

    const {location, originQueries} = useParseQueryLocation({
      calloutPopup: true,
    });

    const Provider = useMemo(
      () => (tooltipStorageKey ? TooltipProvider : ({children}) => children),
      [tooltipStorageKey]
    );

    const {rdLayout, isHybrid, userInteraction, lastCachedCenter, mapStyle} = useAppSelector(
      (state) => ({
        rdLayout: state.layout,
        isHybrid: state.layout.isHybrid,
        userInteraction: state.userInteraction,
        lastCachedCenter: state.map.lastCachedCenter,
        searchBarHeight: state.layout.appSize.searchBarHeight,
        mapStyle: state.map.style,
        map: state.map,
      })
    );

    const activeId = useMemo(() => {
      if (
        userInteraction.activePoi &&
        ['marker', 'rotate'].includes(userInteraction.trigger || '')
      ) {
        return userInteraction.activePoi;
      }
      return undefined;
    }, [userInteraction]);

    const drawerProps = useListDrawer({
      windowHeight: rdLayout.windowSize.height,
      statusBarHeight: statusBarHeight || 0,
      isLandscape: rdLayout.appSize.isLandscape,
      listHandleHeight: 80,
      topAreaHeight: 43,
      initListMode: listMode,
      topAreaPadding: listTopAreaPadding,
      ...drawerOptions,
    });

    const calcTransform = useMemo(() => {
      const isForceMovePosition = forceMoveDrawer !== null;
      const mapY = `${isForceMovePosition ? 0 : drawerProps.position.map * -1}px`;
      const mapDuration = `${drawerProps.position.moveDelay}ms`;
      const listY = `${
        isForceMovePosition ? (forceMoveDrawer || 0) * -1 : drawerProps.position.list * -1
      }px`;
      const listSafeAreaY =
        isForceMovePosition || drawerProps.mode.mode === EListMode.TOP
          ? 'env(safe-area-inset-bottom)'
          : '0px';
      const listDuration = `${isForceMovePosition ? 0 : drawerProps.position.moveDelay}ms`;

      return {mapY, mapDuration, listY, listSafeAreaY, listDuration};
    }, [
      forceMoveDrawer,
      drawerProps.position.map,
      drawerProps.position.moveDelay,
      drawerProps.position.list,
      drawerProps.mode.mode,
    ]);

    useEffect(() => {
      onChangePositionMode?.(drawerProps.mode);
    }, [drawerProps.mode, onChangePositionMode]);

    const setSmoothScroll = useCallback((isSmooth) => {
      if (refScrollList.current) {
        refScrollList.current.style.scrollBehavior = isSmooth ? 'smooth' : 'auto';
      }
    }, []);

    const handleClickRefresh = useCallback(
      (refreshCenter) => {
        setShowRefresh(false);
        onRefresh?.(refreshCenter);
        dispatch(actions.userInteraction.setDragMap(false));
        dispatch(actions.userInteraction.setRefreshStart(true));
      },
      [onRefresh, dispatch]
    );

    const handleAfterPosition = useCallback((center) => {
      setShowRefresh(true);
      dispatch(actions.map.setLastCachedCenter({...center, from: 'app'}));
    }, []);

    useEffect(() => {
      document.body.style.overflow = 'hidden';
      drawerProps.init();

      return () => {
        document.body.style.overflow = '';
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (fixedContentMode) {
        setListBottomPadding(
          rdLayout.windowSize.height -
            headerHeight -
            (rdLayout.appSize.isLandscape && statusBarHeight ? statusBarHeight : 0)
        );
        return;
      }

      if (refScrollList.current) {
        const elementList = refScrollList.current.querySelectorAll<HTMLElement>('[data-type=poi]');
        const lastSearchElement =
          (elementList[elementList.length - 1] as HTMLLIElement) ||
          refScrollList.current.querySelector('[data-type=popup]');

        if (lastSearchElement) {
          setListBottomPadding(
            rdLayout.windowSize.height -
              headerHeight -
              lastSearchElement.offsetHeight -
              (rdLayout.appSize.isLandscape && statusBarHeight ? statusBarHeight : 0) -
              (showFixedTopBtn ? TOP_BTN_HEIGHT : 0)
          );
        } else {
          setListBottomPadding(0);
        }
      }
    }, [
      rdLayout.windowSize.height,
      rdLayout.appSize.isLandscape,
      headerHeight,
      statusBarHeight,
      list,
      drawerProps.position.list,
      fixedContentMode,
    ]);

    useEffect(() => {
      onChangeBottomPadding && onChangeBottomPadding(listBottomPadding);
    }, [listBottomPadding, onChangeBottomPadding]);

    useEffect(() => {
      const landscape = rdLayout.appSize.isLandscape;

      if (landscape) {
        setForceMoveDrawer(rdLayout.windowSize.height);
      }

      return () => {
        landscape && setForceMoveDrawer(null);
      };
    }, [rdLayout.windowSize.height, rdLayout.appSize.isLandscape]);

    useEffect(() => {
      const listModeMap = {
        [EListMode.TOP]: () => drawerProps.moveTopMode(),
        [EListMode.CENTER]: () => drawerProps.moveCenterMode(),
        [EListMode.BOTTOM]: () => drawerProps.moveBottomMode(),
      };

      if (listMode) {
        listModeMap[listMode]?.();
      }

      return () => {
        listMode === EListMode.BOTTOM && setSmoothScroll(false);
      };
    }, [listMode]);

    useEffect(() => {
      onChangeListMode?.(drawerProps.mode.mode);
    }, [drawerProps.mode.mode]);

    useEffect(() => {
      if (activeId) {
        const position =
          document.querySelector<HTMLDivElement>(`[data-id="${activeId}"]`)?.offsetTop || 0;

        // https://tmobi.slack.com/archives/C04PS6R28TX/p1731567172121559?thread_ts=1731566868.059089&cid=C04PS6R28TX
        if (!position) {
          // 1000ms 후 한 번만 재시도, childPoi 접혀있는 경우 처리
          window.setTimeout(() => {
            const retryPosition =
              document.querySelector<HTMLDivElement>(`[data-id="${activeId}"]`)?.offsetTop || 0;
            const margin = retryPosition > extraMargin ? extraMargin : 0;

            window.setTimeout(() => {
              listMoveToTop(retryPosition + margin);
              setSmoothScroll(true);
            }, 0);
          }, 500);
          return;
        }

        const margin = position > extraMargin ? extraMargin : 0;
        window.setTimeout(() => {
          // 콜아웃에서 비활성 마커클릭해서 넘어올때 아직 드로워 노출전이라 스크롤이 안먹히므로 timeout을 통해 뒤로 빼서 올라온다음 스크롤 먹도록 수정
          // 기존에는 이벤트 자체가 react 이벤트라 갱신 이후에 동작이라 문제 없었지만 native 이벤트 등록이 되면서 순서의 조정이 필요해짐
          // refScrollList.current?.scrollTo(0, position + margin);
          listMoveToTop(position + margin);
          setSmoothScroll(true);
        }, 0);
      }
    }, [activeId, setSmoothScroll, extraMargin]);

    useEffect(() => {
      if (userInteraction.dragMap && lastCachedCenter && !userInteraction.calloutInfo) {
        setShowRefresh(true);
      }
      return () => {
        userInteraction.dragMap && setShowRefresh(false);
      };
    }, [lastCachedCenter, userInteraction.dragMap, userInteraction.calloutInfo]);

    useEffect(() => {
      const isIncludeQuery = originQueries.calloutPopup;

      if (userInteraction.calloutInfo && !isIncludeQuery) {
        history.push(location);
      }

      if (!userInteraction.calloutInfo && isIncludeQuery) {
        history.goBack();
      }
    }, [userInteraction.calloutInfo, originQueries.calloutPopup]);

    useEffect(() => {
      if (firstRenderFlag.current && !originQueries.calloutPopup) {
        dispatch(actions.userInteraction.clearCalloutInfo());
        dispatch(actions.userInteraction.setInteraction({drawerMode: EListMode.CENTER}));
      }
    }, [dispatch, originQueries.calloutPopup]);

    useEffect(() => {
      if (list.length === 0) {
        setSmoothScroll(false);
        listMoveToTop(0);
        setSmoothScroll(true);
      }
    }, [list]);

    useEffect(() => {
      dispatch(actions.layout.setListLeft(refList.current?.offsetLeft || 0));
    }, [
      rdLayout.windowSize.width,
      rdLayout.windowSize.height,
      rdLayout.appSize.isLandscape,
      rdLayout.refreshEvent.getListLeft,
      dispatch,
    ]);

    useEffect(() => {
      if (!drawerProps.isDragging) {
        const actionId = {
          [EDirection.UP]: EDrawerActionId.DRAG_UP,
          [EDirection.DOWN]: EDrawerActionId.DRAG_DOWN,
        }[drawerProps.direction];

        actionId && sendClickLogWithMapView(actionId);
      }
    }, [drawerProps.isDragging]);

    const toggleMode = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        const mode = drawerProps.mode;
        const mapview = mode.isBottom ? 0 : mode.isCenter ? 1 : mode.isStarted ? 2 : null;

        if (mapview !== null) {
          sendClickLog(EDrawerActionId.TAP, {
            mapview,
          });
        }

        if (drawerProps.mode.isCenter) {
          drawerProps.moveBottomMode();
        } else {
          drawerProps.moveCenterMode();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [drawerProps.mode.isCenter]
    );

    const handleMoveListToTop = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        listMoveToTop(0);
        sendClickLogWithMapView(EListActionId.TAP_TO_TOP);
      },
      [sendClickLogWithMapView]
    );

    const handleScroll = useCallback(
      (e) => {
        const {scrollHeight, clientHeight, scrollTop} = e.target;

        setTopBtn(
          isLastPage && scrollHeight - clientHeight - scrollTop < TO_TOP_SHOW_SCROLL_HEIGHT
            ? true
            : false
        );

        onScroll?.(scrollTop);
      },
      [isLastPage, onScroll]
    );

    useEffect(() => {
      if (!isLastPage) {
        setTopBtn(false);
      }
    }, [isLastPage]);

    useEffect(() => {
      firstRenderFlag.current = true;
    }, []);

    return (
      <DrawerProvider drawerProps={drawerProps}>
        <div
          className={classNames(s.page_wrap, {
            [s.hybrid_page]: isHybrid,
          })}
        >
          <div
            className={s.map_wrap}
            style={
              userInteraction.calloutInfo
                ? {}
                : {
                    transform: `translateY(${calcTransform.mapY})`,
                    transitionDuration: calcTransform.mapDuration,
                  }
            }
          >
            {MapComponent(drawerProps)}
          </div>

          <div
            className={classNames(s.list_wrap, {
              [s.is_hide]: hide,
            })}
            ref={refList}
            style={
              {
                display: userInteraction.calloutInfo ? 'none' : '',
                transform: `translateY(calc(${calcTransform.listY} + ${calcTransform.listSafeAreaY})) translateZ(0)`,
                transitionDuration: calcTransform.listDuration,
                '--top-padding': `${paddingTop || 0}px`,
                '--handle-border-radius': `${handleBorderRadius}px`,
              } as CSSProperties
            }
          >
            {
              <div
                className={classNames(s.floating_wrap, s.portrait, {
                  // [s.hidden]: drawerProps.position.percent > 0.9,
                  [s.disable]: drawerProps.position.percent > 0.9,
                })}
              >
                {renderCurrentPosition ? (
                  renderCurrentPosition({
                    onAfterPosition: handleAfterPosition,
                    disableAutoMove: true,
                  })
                ) : (
                  <CurrentPositionButton
                    className={s.current_button}
                    onAfterPosition={handleAfterPosition}
                    disableAutoMove={true}
                  />
                )}

                {refreshButtonVisible && showRefresh && (
                  <PlaceRefreshButton
                    onClickRefresh={handleClickRefresh}
                    isStaticLabel={isStaticRefreshLabel}
                  />
                )}

                {!refreshButtonVisible && <RankingLocationButton />}

                {!refreshButtonVisible && hide && <RankingListModeButton onclick={setHide} />}

                {saveToggleButtonVisible && isOverNewFavoriteVersion() && <SaveToggleButton />}

                {/* https://tmobi.atlassian.net/browse/LCC-1920 */}
                {/* {!showRefresh && visibleTNowLanding && (
                    <TNowLandingButton className={s.tnow_button}>주변 T지금</TNowLandingButton>
                  )} */}
              </div>
            }

            {!hide && (
              <Provider storageKey={StorageKey.TNOW_TOOLTIP} scrollElement={refScrollList.current}>
                <div
                  className={s.list_header}
                  {...drawerProps.handlers()}
                  ref={(node) => {
                    if (node) {
                      setHeaderHeight(node.offsetHeight);
                    }
                  }}
                >
                  <div
                    className={s.handle_wrap}
                    onClick={toggleMode}
                    style={handleSize ? {height: handleSize} : {}}
                  >
                    <div className={s.handle} />
                  </div>

                  {ListHeader}
                </div>
                {/* 콘텐츠(리스트) */}
                {fixedContentMode && FixedContent ? (
                  <div ref={refScrollList} style={{height: '100%'}}>
                    {FixedContent}
                  </div>
                ) : (
                  <div
                    className={classNames(s.scroll_wrap, {
                      [s.is_scroll_disable]: listMode === EListMode.BOTTOM,
                    })}
                    ref={refScrollList}
                    onScroll={handleScroll}
                  >
                    {List}

                    {showFixedTopBtn && listBottomPadding > 0 && (
                      <div className={s.list_bottom_padding}>
                        <div className={s.fixed_top_btn} onClick={handleMoveListToTop}>
                          <IconToTop />
                        </div>
                      </div>
                    )}

                    <div
                      style={{height: drawerProps.mode.isTop ? 0 : listBottomPadding}}
                      className={classNames(s.pad, {
                        [s.is_white]: isPadColorWhite,
                      })}
                    />
                  </div>
                )}
              </Provider>
            )}
          </div>

          <DevInfo
            left={10}
            bottom={60}
            color="red"
            infos={[
              {value: drawerProps.mode, color: 'blue'},
              {value: {basePoint: drawerProps.basePoint}, color: 'orange'},
              {value: drawerProps.position, color: 'red'},
              {value: drawerProps.size, color: 'green'},
            ]}
          />
          {!isHideToTop && (
            <div
              className={classNames(s.btn_to_top, {
                [s.show]:
                  (rdLayout.appSize.isLandscape || !drawerProps.mode.isBottom) &&
                  showTopBtn &&
                  list.length > 0,
              })}
              onClick={handleMoveListToTop}
            >
              <IconToTop />
            </div>
          )}

          {!rdLayout.appSize.isLandscape && bottomGradientVisible && (
            <div
              className={classNames(s.gradient, {
                [s.night]: mapStyle === EMapStyle.NIGHT,
              })}
            >
              <div className={s.top} />
              <div className={s.middle} />
              <div className={s.bottom} />
            </div>
          )}
        </div>
        <div className={classNames(s.floating_wrap, s.landscape)}>
          <CurrentPositionButton
            className={s.current_button}
            onAfterPosition={handleAfterPosition}
            disableAutoMove={true}
          />
          {refreshButtonVisible && showRefresh && (
            <PlaceRefreshButton
              onClickRefresh={handleClickRefresh}
              isStaticLabel={isStaticRefreshLabel}
            />
          )}

          {!refreshButtonVisible && <RankingLocationButton />}
          {/* https://tmobi.atlassian.net/browse/LCC-1920 */}
          {/* {!showRefresh && visibleTNowLanding && (
            <TNowLandingButton className={s.tnow_button}>주변 T지금</TNowLandingButton>
          )} */}

          {saveToggleButtonVisible && <SaveToggleButton className={s.save_button} />}
        </div>
        {originQueries.calloutPopup && userInteraction.calloutInfo && (
          <PoiCalloutPopup
            calloutInfo={userInteraction.calloutInfo}
            saveToggleButtonVisible={saveToggleButtonVisible}
          />
        )}
      </DrawerProvider>
    );
  }
);

export default DrawerContainer;
