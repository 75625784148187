import {
  EActionId,
  EImpressionActionId,
  ETNowActionId,
  EMapActionId,
  ECalloutActionId,
  EDrawerActionId,
  EListActionId,
  ELocationActionId,
  EFilterActionId,
  EDurationActionId,
} from 'constant/Log';

export type TTlaCommon = {
  token?: string;
  log_version?: string;
  logbox_version?: string;
  app_version?: string;
  local_time?: string;
  sub_session_id?: string;
  session_id?: string;
  ip?: string;
};

export type TTlaMeta = {
  app_id?: string;
  service_id?: string;
  page_id?: string;
  page_type?: string;
  section_id?: string;
  action_id?: string;
};

export type TTlaEnv = {
  document_title?: string;
  url?: string;
  referrer?: string;
  device_id?: string;
  device_model?: string;
  os_name?: string;
  os_version?: string;
  browser_name?: string;
  browser_version?: string;
  manufacturer?: string;
  language_code?: string;
  resolution?: string;
  screen_width?: string;
  screen_height?: string;
  network_type?: string;
  carrier_name?: string;
};

export type TTlaCustom = {
  [key: string]: string;
};

export type TTlaData = {
  common: TTlaCommon;
  meta: TTlaMeta;
  env: TTlaEnv;
  custom: TTlaCustom;
};

export type TActionId =
  | EActionId
  | EImpressionActionId
  | EDurationActionId
  | EDrawerActionId
  | EListActionId
  | ELocationActionId
  | ETNowActionId
  | EMapActionId
  | ECalloutActionId
  | EFilterActionId
  | 'view.ad'
  | 'view.tmap_ranking_poi'
  | 'tap.ad'
  | 'log.ad'
  | 'tap.back'
  | 'tap.searchbox'
  | 'tab_tap.history'
  | 'tab_tap.history_edit'
  | 'tab_tap.bookmark'
  | 'tab_tap.address'
  | 'tab_tap.map'
  | 'tap.lastD'
  | 'tap.lastA'
  | 'tap.lastS'
  | 'tap.lastT'
  | 'tap.lastD_bookmark'
  | 'tap.lastT_bookmark'
  | 'tap.delete'
  | 'tap.fail_refresh'
  | 'popup_tap.setdestination'
  | 'popup_tap.setthrough'
  | 'popup_tap.setdestination_close'
  | 'tap.checkbox'
  | 'tap.selectall'
  | 'popup_tap.delete_cancel'
  | 'popup_tap.delete_ok'
  | 'tap.cancel_edit'
  | 'tap.home'
  | 'tap.office'
  | 'tap.bookmark'
  | 'tap.bookmark_info'
  | 'tap.poi'
  | 'tap.route'
  | 'tap.timeorder'
  | 'tap.textorder'
  | 'tap.order'
  | 'tap.bookmark_route'
  | 'tap.addr_depth1'
  | 'tap.addr_depth2'
  | 'tap.addr_depth3'
  | 'tab_tap.road'
  | 'tab_tap.village'
  | 'tab_tap.addr_depth1'
  | 'tab_tap.addr_depth2'
  | 'tab_tap.addr_depth3'
  | 'tap.x'
  | 'ime_tap.searchbtn'
  | 'longtap.map'
  | 'panning.map'
  | 'pinchin.map'
  | 'pinchout.map'
  | 'tap.map_poi'
  | 'tap.searchbtn'
  | 'top_tap.x'
  | 'top_tap.searchbtn'
  | 'tap.auto'
  | 'tap.auto_poi'
  | 'tap.compass'
  | 'tap.search_mylocation'
  | 'tap.search_maplocation'
  | 'tap.addpoi'
  | 'tap.fail_refresh'
  | 'impression.poilist'
  | 'list_tap.poi'
  | 'list_tap.poi_detail'
  | 'list_tap.poigrp'
  | 'list_tap.poigrp_detail'
  | 'list_tap.poi_coupon'
  | 'sort_tap.score'
  | 'sort_tap.dist'
  | 'tap.research_locatedquery'
  | 'tap.research_general'
  | 'tap.map'
  | 'tap.map_dot'
  | 'tap.map_bookmark'
  | 'tap.map_recent'
  | 'tap.reset'
  | 'tap.all'
  | 'tap.tmap_pay'
  | 'tap.public_use'
  | 'tap.select_charger_status'
  | 'tap.public_parkinglot'
  | 'tap.near_poi_category'
  | 'tap.belt_banner'
  | 'tap.near_poi_category'
  | 'tap.all'
  | 'tap.cherryblossom'
  | 'tap.parkinglot'
  | 'tap.restaurant'
  | 'tap.tourist_dest'
  | 'tap.accommodation'
  | 'tap.cafe'
  | 'tap.sharing'
  | 'tap.sticky_theme'
  | 'horizontal_scroll.sticky_theme'
  | 'tap.refresh'
  | 'tap.poilist'
  | 'scroll_up.poilist'
  | 'scroll_down.poilist'
  | 'tap.recommend_poi_more'
  | 'tap.poilist_recommend'
  | 'horizontal_scroll.recommend_poi'
  | 'tap.tmap_popular'
  | 'tap.banner'
  | 'tap.poilist_more'
  | 'tap.route_poi_detail'
  | 'tap.address_search'
  | 'tap.history_edit'
  | 'tap.history_delete'
  | 'impression.historylist'
  | 'tap.near_poi_recommendation'
  | 'list_tap.near_poi_recommendation'
  | 'tab_tap.poi'
  | 'tab_tap.navi_route'
  | 'tab_tap.public_trans_route'
  | 'list_tap.bookmark'
  | 'list_tap.navi_route'
  | 'list_tap.public_trans_route'
  | 'swipe.bookmark_type'
  | 'list_tap.addr_depth1'
  | 'list_tap.addr_depth2'
  | 'list_tap.addr_depth3'
  | 'tap.nearby_tnow'
  | 'list_tap.select_poi'
  | 'list_tap.poi_direction'
  | 'list_tap.poigrp_direction'
  | 'impression.tnow_recommendation'
  | 'impression.suggest_query'
  | 'tap.suggest_query'
  | 'list_tap.reservation_accommodation'
  | 'tap.reservation'
  | 'tab_tap.place'
  | 'tab_tap.bus'
  | 'tab_tap.bus_station'
  | 'tap.search_location'
  | 'filter_tap.city'
  | 'tap.select_city'
  | 'tap.to_bike'
  | 'tap.category.all'
  | 'tap.category.food'
  | 'tap.category.cafe'
  | 'tap.category.tour'
  | 'tap.category.hotel'
  | 'tap.reserve'
  | 'tap.waiting'
  | 'tap.catch_version_popup.next_time'
  | 'tap.catch_version_popup.update'
  | 'tap.filter.reserve'
  | 'tap.filter.waiting'
  | 'impression.catch_version_popup'
  | 'tap.filter'
  | 'tap.filter_TMAP_FAMOUS'
  | 'tap.filter_OPEN_NOW'
  | 'tap.filter_WAITING_RESERVATION'
  | 'tap.filter_POI_PARK'
  | 'tap.filter_FUDKOR'
  | 'tap.filter_FUDCHN'
  | 'tap.filter_FUDWES'
  | 'tap.filter_FUDJPN'
  | 'tap.filter_CAFEALL'
  | 'tap.filter_FUDBAR'
  | 'tap.poi_bookmark'
  | 'tap.result_information'
  | 'tap.select_filter'
  | 'tap.filter_reset'
  | 'tap.search_main'
  | 'tap.recommend_keyword'
  | 'tap.tmap_ranking_viewall'
  | 'tap.tmap_ranking_poi'
  | 'tap.festival_viewall'
  | 'tap.festival_poi'
  | 'tap.festival_regionfilter'
  | 'tap.region_chip'
  | 'tap.tmap_ranking_poi'
  | 'tap.re_search'
  | 'tap.sorting_moving'
  | 'tap.sorting_distance'
  | 'tap.view_type_ranking'
  | 'tap.view_type_photo'
  | 'tap.filter_all'
  | 'tap.filter_open'
  | 'tap.filter_reserve_waiting'
  | 'tap.filter_parking'
  | 'tap.filter_food'
  | 'tap.filter_cafe'
  | 'tap.filter_alcohol'
  | 'tap.ranking_list'
  | 'tap.filter_popup_select'
  | 'tap.filter_reset'
  | 'tap.depth_up'
  | 'tap.fail_refresh'
  | 'list_tap.poigrp_open'
  | 'tap.save_onoff'
  | 'tap.callout_save'
  | '';

export enum EPerformanceLogKey {
  WEB_LAUNCH = 'web_launch',
  VSM_INIT = 'vsm_init',
  LIST_API = 'list_api',
  CALL_POSITION = 'call_position',
  INIT_API_RENDER = 'init_api_render',
  VSM_FIRST_PAINT = 'vsm_first_paint',
}
