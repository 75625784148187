import {ERPFlag, TPoiImageInfo} from 'types/App';
import {useParsePoiData} from 'hooks/useParsePoiData';
import {useCallback, useEffect, useState} from 'react';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {useAppSelector} from 'ducks/hooks';
import {TRankItem} from 'ducks/rank/types';
import useThrottle from 'hooks/useThrottle';
import useAppScheme from 'hooks/useAppScheme';
import useAddress from 'hooks/useAddress';
import RankingPoiTitle from './RankingPoiTitle';
import RankingPoiInfo from './RankingPoiInfo';
import RankingPoiSubInfo from './RankingPoiSubInfo';
import RankingPoiImageList from './RankingPoiImageList';

import s from 'styles/components/ranking/RankingPoiPhotoViewItem.module.scss';

type TProps = {
  poiData: {
    imageInfo?: TPoiImageInfo[];
  } & TRankItem;
  isFavorite?: boolean;
  onClickItem: (e) => void;
  onClickFavorite: (isOn: boolean) => void;
};

const RankingPoiPhotoViewItem = ({poiData, onClickItem, onClickFavorite, ...props}: TProps) => {
  const {
    width: windowWidth,
    currentAddressMap,
    activePoi,
  } = useAppSelector((state) => ({
    width: state.layout.windowSize.width,
    currentAddressMap: state.tRank.currentAddressMap,
    activePoi: state.userInteraction.activePoi,
  }));
  const {poiName, categoryName, special, comment, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore, tags} = useParsePoiData(poiData);
  const ableToRun = useThrottle();
  const {fullAddress} = useAddress(poiData);
  const inApp = useAppScheme();

  const {unit} = special?.catchTableWaitingResponse || {};
  const businessHoursInfo =
    special?.businessHourStatus && BUSINESS_HOUR_LABEL[special.businessHourStatus];

  const [isFavorite, setIsFavorite] = useState<boolean>(!!props.isFavorite);

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleSearchFavorite({
          ...poiData,
          address: fullAddress,
          rpFlag: ERPFlag.N_0000,
        })
        .then(({isOn}) => {
          setIsFavorite(isOn);
          onClickFavorite(isOn);
        });
    },
    [ableToRun, fullAddress, inApp, poiData, onClickFavorite]
  );

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  return (
    <div
      className={s.wrap}
      onClick={onClickItem}
      data-type="poi"
      data-id={poiData.listId}
      data-focus={poiData.listId === activePoi}
    >
      <span className={s.img_idx}>{poiData.rank}</span>
      <RankingPoiImageList images={images} poiName={poiName} />

      <div className={s.container}>
        <RankingPoiTitle
          poiName={poiName}
          categoryName={categoryName}
          isFavorite={isFavorite}
          handleClickFavorite={handleClickFavorite}
          isPhotoView={true}
        />

        <RankingPoiInfo
          businessHoursInfo={businessHoursInfo}
          special={special}
          headingScore={headingScore}
          unit={unit}
        />

        {!!comment && (
          <div className={s.description}>
            <div className={s.comment}>{comment}</div>
          </div>
        )}

        <RankingPoiSubInfo
          distance={distance}
          addressNameDepth2={addressNameDepth2}
          currentAddressMap={currentAddressMap}
          tags={tags}
          windowWidth={windowWidth}
        />
      </div>
    </div>
  );
};

export default RankingPoiPhotoViewItem;
