import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ERankingUpDownStatus, ERPFlag, TPoiImageInfo} from 'types/App';
import {useParsePoiData} from 'hooks/useParsePoiData';
import {TRankItem} from 'ducks/rank/types';
import useThrottle from 'hooks/useThrottle';
import useAppScheme from 'hooks/useAppScheme';
import useAddress from 'hooks/useAddress';
import {useAppSelector} from 'ducks/hooks';
import Thumbnail from 'components/Thumbnail';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import RankingPoiInfo from './RankingPoiInfo';
import {RankingPoiDistance} from './RankingPoiSubInfo';
import {RankingFavorite} from './RankingPoiTitle';
import {ReactComponent as IcoNew} from 'resource/images/ico_new.svg';
import {ReactComponent as IcoRankingUp} from 'resource/images/ico_ranking_up.svg';
import {ReactComponent as IcoRankingDown} from 'resource/images/ico_ranking_down.svg';
import {IcoDotBetweenBold} from 'components/@tmds/icons/IcoDotBetweenBold';

import s from 'styles/components/ranking/RankingPoiItem.module.scss';

type TProps = {
  poiData: {
    imageInfo?: TPoiImageInfo[];
  } & TRankItem;
  isFavorite?: boolean;
  onClickItem: (e) => void;
  onClickFavorite: (isOn: boolean) => void;
};

export const RANKING_UP_DOWN_ICON = {
  [ERankingUpDownStatus.UP]: <IcoRankingUp />,
  [ERankingUpDownStatus.DOWN]: <IcoRankingDown />,
  [ERankingUpDownStatus.NEW]: <IcoNew />,
};

const RankingPoiItem = ({poiData, onClickItem, onClickFavorite, ...props}: TProps) => {
  const {currentAddressMap, activePoi} = useAppSelector((state) => ({
    currentAddressMap: state.tRank.currentAddressMap,
    activePoi: state.userInteraction.activePoi,
  }));
  const {poiName, categoryName, special, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore} = useParsePoiData(poiData);
  const ableToRun = useThrottle();
  const inApp = useAppScheme();
  const {fullAddress} = useAddress(poiData);

  const {unit} = special?.catchTableWaitingResponse || {};
  const [isFavorite, setIsFavorite] = useState<boolean>(!!props.isFavorite);

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleSearchFavorite({
          ...poiData,
          address: fullAddress,
          rpFlag: ERPFlag.N_0000,
        })
        .then(({isOn}) => {
          setIsFavorite(isOn);
          onClickFavorite(isOn);
        });
    },
    [ableToRun, fullAddress, inApp, poiData, onClickFavorite]
  );

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  return (
    <div
      className={s.wrap}
      onClick={onClickItem}
      data-type="poi"
      data-id={poiData.listId}
      data-focus={activePoi === poiData.listId}
    >
      <div className={s.ranking}>
        <span className={s.number}>{poiData.rank}</span>
        {special?.rankingUpDownInfo?.status &&
          RANKING_UP_DOWN_ICON[special?.rankingUpDownInfo.status]}
      </div>

      <div className={s.contents_wrap}>
        <div className={s.image_wrap}>
          <Thumbnail
            className={s.img}
            imgSrc={getImageUrl(images[0], EImageSize.THUMBNAIL)}
            alt={`${poiData.poiName} 이미지`}
          />
        </div>

        <div className={s.info_wrap}>
          <div className={s.title}>
            <span>{poiName}</span>
          </div>
          <RankingPoiInfo
            businessHoursInfo={null}
            special={special}
            headingScore={headingScore}
            unit={unit}
          />

          <div
            className={classNames(s.sub_info_wrap, {
              [s.has_review]: special.avgStar || special.reviewCount,
            })}
          >
            <RankingPoiDistance
              distance={distance}
              addressNameDepth2={addressNameDepth2}
              currentAddressMap={currentAddressMap}
            />

            <IcoDotBetweenBold width={12} height={12} color="gray200" />

            <span className={s.category}>{categoryName}</span>
          </div>
        </div>
      </div>

      <RankingFavorite
        isFavorite={isFavorite}
        handleClickFavorite={handleClickFavorite}
        className={s.favorite}
      />
    </div>
  );
};

export default RankingPoiItem;
