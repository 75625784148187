import {useAppSelector, useAppDispatch} from 'ducks/hooks';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {useHistory} from 'react-router';
import actions from 'ducks/actions';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {PoiCalloutPopup} from 'components/PoiCalloutPopup';
import useReverseGeocoding from 'hooks/useReverseGeocoding';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {parsePoiInfoToNavInfo} from 'utils/search';
import {sendSearchClickLog} from 'utils/logManager';
import {ReactComponent as IconLocationSolid} from 'resource/images/@tmds_solid/ico_location_solid.svg';
import classNames from 'classnames';
import VSMCompass from 'components/VSMCompass';
import CurrentPositionButton from 'components/CurrentPositionButton';
import Skeleton from 'components/Skeleton';
import {EDetailButtonType, ERPFlag, ESkeletonType} from 'types/App';
import useAddress from 'hooks/useAddress';
import {EAddressType} from 'ducks/userInfo/types';
import PickOnMap from 'components/PickOnMap';

import s from 'styles/components/search/SearchPickOnMap.module.scss';
import {getAppActionButton} from 'utils/tmapUtils';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import {TPoiDataJson} from '@lcc/tmap-inapp';

const INVALID_AREA_TEXT = '서비스 제공지역이 아닙니다';
const BOTTOM_ADDRESS_HEIGHT = 160;

const SearchPickOnMap = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {location, originQueries} = useParseQueryLocation({poiCalloutPopup: true});
  const {pickOnMapCalloutInfo, nowCenter, nowBearing, nowPitch} = useAppSelector((state) => ({
    pickOnMapCalloutInfo: state.userInteraction.pickOnMapCalloutInfo,
    nowCenter: state.map.nowCenter,
    nowBearing: state.map.nowBearing,
    nowPitch: state.map.nowPitch,
  }));
  const refPrevText = useRef('');
  const {moveToSelectDestinationAction, extra, reqType, reqMode} = useMoveToTarget({
    from: EFromType.PICK_ON_MAP,
  });
  const geoResult = useReverseGeocoding(nowCenter);
  const {showMain} = useSearchPageVisible();
  const refByClickPoi = useRef(false);

  const actionType = useMemo(() => {
    return getAppActionButton({extra, reqType, reqMode});
  }, [extra, reqType, reqMode]);

  const {absoluteFullAddress} = useAddress(geoResult.data);

  const {routePoiJson, displayName} = useMemo(() => {
    const isJibunAddress = absoluteFullAddress.type === EAddressType.JIBUN;
    const buildingName = geoResult.data?.buildingName ? `(${geoResult.data?.buildingName})` : '';
    const address = absoluteFullAddress.fullAddress;
    const poiName = `${address} ${buildingName} `.trim();
    const coord = isJibunAddress
      ? geoResult?.data?.jibunAddrCoordination
      : geoResult?.data?.roadAddrCoordination;
    const pkey = isJibunAddress ? geoResult?.data?.jibunPkey : geoResult?.data?.roadPkey;

    if (coord?.navX && coord?.navY) {
      const poiJson = parsePoiInfoToNavInfo({
        pkey: pkey || '',
        poiId: '',
        navSeq: '',
        navX: `${coord.navX || ''}`,
        navY: `${coord.navY || ''}`,
        centerX: `${coord?.centerX || ''}`,
        centerY: `${coord?.centerY || ''}`,
        rpFlag: ERPFlag.N_G000,
        poiName,
        address,
        tel: '',
        stationId: pickOnMapCalloutInfo?.stationSktId,
        publicTransportType: pickOnMapCalloutInfo?.publicTransportType,
      });

      return {routePoiJson: poiJson, displayName: poiName};
    }
    return {routePoiJson: null, displayName: ''};
  }, [
    absoluteFullAddress.fullAddress,
    absoluteFullAddress.type,
    geoResult.data?.buildingName,
    geoResult.data?.jibunAddrCoordination,
    geoResult.data?.jibunPkey,
    geoResult.data?.roadAddrCoordination,
    geoResult.data?.roadPkey,
    pickOnMapCalloutInfo,
  ]);

  const handleUpdateInfo = useCallback(
    ({poiDataList}: {poiDataList: TPoiDataJson[]}) => {
      if (!pickOnMapCalloutInfo) {
        return;
      }
      const favId = poiDataList?.[0]?.favId || '';

      dispatch(
        actions.userInteraction.setPickOnMapCalloutInfo({
          ...pickOnMapCalloutInfo,
          favId,
        })
      );
    },
    [pickOnMapCalloutInfo, dispatch]
  );

  const addressText = useMemo(() => {
    if (geoResult.loaded) {
      return displayName || INVALID_AREA_TEXT;
    }

    if (geoResult.loading) {
      return refPrevText.current;
    }
  }, [geoResult, displayName]);

  const isInvalidInfo = useMemo(() => addressText === INVALID_AREA_TEXT, [addressText]);
  const isAddressFetching = useMemo(() => !addressText, [addressText]);

  useEffect(() => {
    refPrevText.current = !!addressText ? addressText : '';
  }, [addressText]);

  useEffect(() => {
    if (!showMain) {
      return;
    }
    if (!pickOnMapCalloutInfo && originQueries.poiCalloutPopup) {
      history.goBack();
    }
    if (pickOnMapCalloutInfo && !originQueries.poiCalloutPopup) {
      if (refByClickPoi.current) {
        history.push(location);
      } else {
        dispatch(actions.userInteraction.clearPickOnMapCalloutInfo());
      }
      refByClickPoi.current = false;
    }
  }, [showMain, originQueries.poiCalloutPopup, pickOnMapCalloutInfo, location, history, dispatch]);

  return (
    <div className={s.wrap}>
      <VSMCompass className={s.vsm_compass} initBearing={nowBearing} initPitch={nowPitch} />
      {showMain && (
        <PickOnMap
          bottomAddressHeight={BOTTOM_ADDRESS_HEIGHT}
          onClickPoi={() => {
            refByClickPoi.current = true;
          }}
          initCenter={
            pickOnMapCalloutInfo?.lon && pickOnMapCalloutInfo?.lat
              ? {
                  lon: pickOnMapCalloutInfo.lon,
                  lat: pickOnMapCalloutInfo.lat,
                }
              : nowCenter
          }
        />
      )}
      <div className={classNames(s.action, {[s.hide]: pickOnMapCalloutInfo})}>
        <CurrentPositionButton className={s.current_position} disableAutoMove={true} />
        <div className={classNames(s.info_wrap, {[s.invalid_area]: isInvalidInfo})}>
          <Skeleton
            type={ESkeletonType.SEARCH_PICK_ON_MAP}
            apiStatus={{
              loaded: !isAddressFetching,
              loading: isAddressFetching,
              data: {},
            }}
          >
            <div className={s.address}>
              <i>
                <IconLocationSolid />
              </i>
              <p className={s.text}>{addressText}</p>
            </div>
            <div className={s.route_btn}>
              <button
                disabled={!routePoiJson}
                onClick={() => {
                  routePoiJson && moveToSelectDestinationAction(routePoiJson);
                  sendSearchClickLog('tap.searchbtn', {
                    search_query: addressText,
                  });
                }}
              >
                {actionType === EDetailButtonType.CONFIRM ? '확인' : '길안내'}
              </button>
            </div>
          </Skeleton>
        </div>
      </div>
      {pickOnMapCalloutInfo && (
        <PoiCalloutPopup calloutInfo={pickOnMapCalloutInfo} onUpdateInfo={handleUpdateInfo} />
      )}
    </div>
  );
};

export default SearchPickOnMap;
