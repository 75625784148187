import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import TMapSender from '@lcc/tmap-inapp';
import {EReverseGeocodingError, ERPFlag} from 'types/App';

import actions from 'ducks/actions';
import {useAppSelector} from 'ducks/hooks';
import useMap from 'hooks/useMap';
import useAddress from 'hooks/useAddress';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useReverseGeocoding, {TReverseGeocodingApiStatus} from 'hooks/useReverseGeocoding';

import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';

import {addressInfoFromReverseGeoData} from 'utils/map';
import {sendSearchClickLog} from 'utils/logManager';

import PickOnMap from 'components/PickOnMap';
import BottomButtons from 'components/BottomButtons';
import VSMCompass from 'components/VSMCompass';
import CurrentPositionButton from 'components/CurrentPositionButton';
import {PoiCalloutPopup} from 'components/PoiCalloutPopup';

import {ReactComponent as AddressIcon} from 'resource/images/@tmds_solid/ico_location_solid.svg';

import s from 'styles/components/legacySearch/PickOnMapTab.module.scss';

const PickOnMapTab = () => {
  const dispatch = useDispatch();
  const vsm = useVSMInterfaceConsumer();
  const {moveToCenter} = useMap();
  const {isUseJibun} = useAddress();
  const [reloadClickCount, setReloadClickCount] = useState<number>(0);

  const {nowCenter, pickOnMapCalloutInfo, nowBearing, nowPitch, nowZoom} = useAppSelector(
    (state) => ({
      nowCenter: state.map.nowCenter,
      nowBearing: state.map.nowBearing,
      nowPitch: state.map.nowPitch,
      nowZoom: state.map.zoom,
      pickOnMapCalloutInfo: state.userInteraction.pickOnMapCalloutInfo,
    })
  );

  const {moveToSelectDestinationAction} = useMoveToTarget({
    from: EFromType.PICK_ON_MAP,
  });

  const geoResult = useReverseGeocoding(nowCenter);
  const [loadedGeoResult, setLoadedGeoResult] =
    useState<Nullable<TReverseGeocodingApiStatus>>(null);

  const {addressStr, addressSubStr} = useMemo(() => {
    if (loadedGeoResult?.data) {
      const addressInfoFromGeo = addressInfoFromReverseGeoData(loadedGeoResult?.data, isUseJibun);

      return {addressStr: addressInfoFromGeo.absoluteExist, addressSubStr: addressInfoFromGeo.sub};
    }

    return {addressStr: '', addressSubStr: ''};
  }, [loadedGeoResult, isUseJibun]);

  useEffect(() => {
    vsm.map?.resize();
    nowCenter && moveToCenter({...nowCenter, zoom: nowZoom}, {animate: false});
  }, [vsm.map]);

  useEffect(() => {
    geoResult.loaded && setLoadedGeoResult(geoResult);
  }, [geoResult]);

  const handleClick = useCallback(() => {
    sendSearchClickLog('tap.searchbtn');

    if (loadedGeoResult?.data) {
      const pkey = isUseJibun
        ? loadedGeoResult.data.jibunPkey || loadedGeoResult.data.roadPkey
        : loadedGeoResult.data.roadPkey || loadedGeoResult.data.jibunPkey;

      const poiJson = {
        pkey: `${pkey || ''}`,
        poiId: '',
        navSeq: '',
        poiName: addressStr,
        navX: `${loadedGeoResult.data.centerX}`,
        navY: `${loadedGeoResult.data.centerY}`,
        centerX: `${loadedGeoResult.data.centerX}`,
        centerY: `${loadedGeoResult.data.centerY}`,
        address: addressSubStr,
        tel: '',
        rpFlag: ERPFlag.N_G000,
      };

      moveToSelectDestinationAction(poiJson);
    } else {
      const guideText =
        loadedGeoResult?.error === EReverseGeocodingError.NO_DATA
          ? '서비스 제공 지역이 아닙니다.'
          : '네트워크 연결상태를 확인해주세요';
      TMapSender.makeToast(guideText);
    }
  }, [
    loadedGeoResult?.error,
    loadedGeoResult?.data,
    isUseJibun,
    addressStr,
    addressSubStr,
    moveToSelectDestinationAction,
  ]);

  const handleClickReload = useCallback(() => {
    if (reloadClickCount >= 4) {
      window.location.reload();
    }

    setReloadClickCount((prev) => prev + 1);
  }, [reloadClickCount]);

  useEffect(() => {
    return () => {
      dispatch(actions.userInteraction.clearCalloutInfo());
    };
  }, []);

  return (
    <div className={s.search_pick_on_map_wrap}>
      <VSMCompass className={s.vsm_compass} initBearing={nowBearing} initPitch={nowPitch} />
      <PickOnMap initCenter={nowCenter} />

      <div className={s.bottom}>
        <CurrentPositionButton className={s.current_position} disableAutoMove={true} />
        {pickOnMapCalloutInfo ? (
          <PoiCalloutPopup calloutInfo={pickOnMapCalloutInfo} saveToggleButtonVisible={false} />
        ) : (
          <>
            {addressStr && (
              <div className={s.address_info}>
                <span className={s.address_inner_wrap}>
                  <i>
                    <AddressIcon />
                  </i>
                  {addressStr}
                </span>
                <div aria-disabled={true} className={s.reload_area} onClick={handleClickReload} />
              </div>
            )}
            <BottomButtons
              className={s.bottom_button}
              buttons={[
                {
                  title: '확인',
                  active: true,
                  onClick: handleClick,
                  ariaProperties: {
                    'aria-label': '길찾기',
                  },
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PickOnMapTab;
