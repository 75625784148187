import {APP_API_HOST, APP_SERVER_NAME, EProjectEnv} from './Env';

export const URL_IMAGE_CDN = 'https://poi-cdn.tmap.co.kr/POI';

const env = APP_SERVER_NAME || EProjectEnv.DEV;

enum EApiHost {
  PROD_DOMAIN = 'https://sch.tmobiapi.com',
  RTG_DOMAIN = 'https://sch-rtg.tmobiapi.com',
  STAGE_DOMAIN = 'https://sch-stg.tmobiapi.com',
  DEV_DOMAIN = 'https://sch-dev.tmobiapi.com',
}

enum EPoiApiHost {
  PROD_DOMAIN = 'https://poi.tmobiapi.com',
  RTG_DOMAIN = 'https://poi-rtg.tmobiapi.com',
  STAGE_DOMAIN = 'https://poi-stg.tmobiapi.com',
  DEV_DOMAIN = 'https://poi-dev.tmobiapi.com',
}

enum ESuggestApiHost {
  PROD_DOMAIN = 'https://acf.tmap.co.kr:13132',
  STAGE_DOMAIN = 'https://autoc-stg.tmap.co.kr:13131',
  DEV_DOMAIN = 'https://autoc-stg.tmap.co.kr:13131',
}

export enum ESearchWebHost {
  PROD_DOMAIN = 'https://search.tmap.co.kr',
  RTG_DOMAIN = 'https://search-rtg.tmap.co.kr',
  STAGE_DOMAIN = 'https://search-stg.tmap.co.kr',
  DTG_DOMAIN = 'https://search-dtg.tmap.co.kr',
  DEV_DOMAIN = 'https://search-dev.tmap.co.kr',
}

enum EPoiWebHost {
  PROD_DOMAIN = 'https://poiweb.tmap.co.kr',
  RTG_DOMAIN = 'https://poiweb-rtg.tmap.co.kr',
  STAGE_DOMAIN = 'https://poiweb-stg.tmap.co.kr',
  DTG_DOMAIN = 'https://poiweb-dtg.tmap.co.kr',
  DEV_DOMAIN = 'https://poiweb-dev.tmap.co.kr',
}

enum EFrontmanWebHost {
  PROD_DOMAIN = 'https://frontman.tmobiapi.com',
  RTG_DOMAIN = 'https://frontman.tmobiapi.com',
  STAGE_DOMAIN = 'https://frontman-stg.tmobiapi.com',
  DEV_DOMAIN = 'https://frontman-dev.tmobiapi.com',
}

enum EOndaHost {
  PROD_DOMAIN = 'https://tmap.booking-on.com',
  RTG_DOMAIN = 'https://tmap.booking-on.com',
  STAGE_DOMAIN = 'https://tmap.booking-on.com',
  DEV_DOMAIN = 'https://tmap.booking-on.click',
}

export const API_HOST = {
  [EProjectEnv.PROD]: EApiHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: EApiHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: EApiHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: EApiHost.STAGE_DOMAIN,
  [EProjectEnv.DEV]: EApiHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? EApiHost[APP_API_HOST] : EApiHost.DEV_DOMAIN,
}[env];

export const POI_API_HOST = {
  [EProjectEnv.PROD]: EPoiApiHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: EPoiApiHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: EPoiApiHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: EPoiApiHost.STAGE_DOMAIN,
  [EProjectEnv.DEV]: EPoiApiHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? EPoiApiHost[APP_API_HOST] : EPoiApiHost.DEV_DOMAIN,
}[env];

export const SUGGEST_API_HOST = {
  [EProjectEnv.PROD]: ESuggestApiHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: ESuggestApiHost.PROD_DOMAIN,
  [EProjectEnv.STAGE]: ESuggestApiHost.PROD_DOMAIN, // ESuggestApiHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: ESuggestApiHost.PROD_DOMAIN,
  [EProjectEnv.DEV]: ESuggestApiHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? ESuggestApiHost[APP_API_HOST] : ESuggestApiHost.DEV_DOMAIN,
}[env];

export const SEARCH_HOST = {
  [EProjectEnv.PROD]: ESearchWebHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: ESearchWebHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: ESearchWebHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: ESearchWebHost.DTG_DOMAIN,
  [EProjectEnv.DEV]: ESearchWebHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? ESearchWebHost[APP_API_HOST] : window.location.origin,
}[env];

export const POI_HOST = {
  [EProjectEnv.PROD]: EPoiWebHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: EPoiWebHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: EPoiWebHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: EPoiWebHost.DTG_DOMAIN,
  [EProjectEnv.DEV]: EPoiWebHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? EPoiWebHost[APP_API_HOST] : EPoiWebHost.DEV_DOMAIN,
}[env];

export const FRONTMAN_HOST = {
  [EProjectEnv.PROD]: EFrontmanWebHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: EFrontmanWebHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: EFrontmanWebHost.STAGE_DOMAIN,
  [EProjectEnv.DTG]: EFrontmanWebHost.STAGE_DOMAIN,
  [EProjectEnv.DEV]: EFrontmanWebHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? EFrontmanWebHost[APP_API_HOST] : EFrontmanWebHost.DEV_DOMAIN,
}[env];

export const ONDA_HOST = {
  [EProjectEnv.PROD]: EOndaHost.PROD_DOMAIN,
  [EProjectEnv.RTG]: EOndaHost.RTG_DOMAIN,
  [EProjectEnv.STAGE]: EOndaHost.STAGE_DOMAIN,
  [EProjectEnv.DEV]: EOndaHost.DEV_DOMAIN,
  [EProjectEnv.LOCAL]: APP_API_HOST ? EOndaHost[APP_API_HOST] : EOndaHost.DEV_DOMAIN,
}[env];
