import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {ERPFlag, TPoiImageInfo} from 'types/App';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {useParsePoiData} from 'hooks/useParsePoiData';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {TRankItem} from 'ducks/rank/types';
import useThrottle from 'hooks/useThrottle';
import useAddress from 'hooks/useAddress';
import {useAppSelector} from 'ducks/hooks';
import useAppScheme from 'hooks/useAppScheme';
import RankingPoiInfo from './RankingPoiInfo';
import RankingPoiTitle from './RankingPoiTitle';
import RankingPoiSubInfo from './RankingPoiSubInfo';
import RankingPoiImageList from './RankingPoiImageList';

import s from 'styles/components/ranking/RankingPoiCardViewItem.module.scss';

type TProps = {
  focus?: boolean;
  poiData: {
    imageInfo?: TPoiImageInfo[];
    listName: string;
  } & TRankItem;
  isFavorite: boolean;
  onClickItem?: (e) => void;
};

const RankingPoiCardViewItem = ({poiData, onClickItem, ...props}: TProps) => {
  const {currentAddressMap, width: windowWidth} = useAppSelector((state) => ({
    currentAddressMap: state.tRank.currentAddressMap,
    width: state.layout.windowSize.width,
  }));
  const {poiName, categoryName, special, comment, addressNameDepth2} = poiData;
  const {distance, imageInfo: images, headingScore, tags} = useParsePoiData(poiData);
  const {moveToDetail} = useMoveToTarget({});
  const ableToRun = useThrottle();
  const {fullAddress} = useAddress(poiData);
  const inApp = useAppScheme();
  const [isFavorite, setIsFavorite] = useState(!!props.isFavorite);

  const {unit} = special?.catchTableWaitingResponse || {};
  const businessHoursInfo =
    special?.businessHourStatus && BUSINESS_HOUR_LABEL[special.businessHourStatus];

  const handleClickFavorite = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!poiData || !ableToRun()) {
        return;
      }

      inApp
        .toggleSearchFavorite({
          ...poiData,
          address: fullAddress,
          rpFlag: ERPFlag.N_0000,
        })
        .then(({isOn}) => setIsFavorite(isOn));
    },
    [ableToRun, fullAddress, inApp, poiData]
  );

  const handleClickItem = useCallback(
    (e) => {
      moveToDetail(poiData);
      onClickItem?.(e);
    },
    [moveToDetail, poiData, onClickItem]
  );

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  return (
    <div className={s.wrap} onClick={handleClickItem}>
      <RankingPoiImageList images={images} poiName={poiName} isCardMode={true} />

      <div className={s.contents_wrap}>
        <RankingPoiTitle
          poiName={poiName}
          categoryName={categoryName}
          isFavorite={isFavorite}
          handleClickFavorite={handleClickFavorite}
        />

        <RankingPoiInfo
          className={s.info_wrap}
          businessHoursInfo={businessHoursInfo}
          special={special}
          headingScore={headingScore}
          unit={unit}
        />

        {comment && (
          <div
            className={classNames(s.comment_wrap, {
              [s.no_poi_info]:
                !businessHoursInfo && !special?.reviewCount && !(headingScore || unit?.count),
            })}
          >
            <div className={s.intro}>{comment}</div>
          </div>
        )}

        <div className={s.sub_info_wrap}>
          <RankingPoiSubInfo
            distance={distance}
            addressNameDepth2={addressNameDepth2}
            currentAddressMap={currentAddressMap}
            windowWidth={windowWidth}
            tags={tags}
          />
        </div>
      </div>
    </div>
  );
};

export default RankingPoiCardViewItem;
