import {useMemo, Fragment, useCallback} from 'react';
import TMapSender, {TPoiDataJson} from '@lcc/tmap-inapp';
import FavoriteButton from 'components/FavoriteButton';
import ShareButton from 'components/ShareButton';
import {EAppRequestMode, EDetailButtonType} from 'types/App';
import {ECalloutActionId} from 'constant/Log';
import {getCalloutActionButtons} from 'utils/tmapUtils';
import useMoveToTarget from 'hooks/useMoveToTarget';
import useLogger from 'hooks/useLogger';
import useThrottle from 'hooks/useThrottle';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';

import s from 'styles/components/PoiCalloutPopupButtons.module.scss';

const BUTTON_TYPE_LABEL = {
  [EDetailButtonType.CONFIRM]: '확인',
  [EDetailButtonType.DESTINATION]: '도착',
  [EDetailButtonType.VIA]: '경유',
  [EDetailButtonType.DEPARTURE]: '출발',
};

const BUTTON_ACTION_ID = {
  [EDetailButtonType.DEPARTURE]: ECalloutActionId.SET_START,
  [EDetailButtonType.VIA]: ECalloutActionId.SET_THROUGH,
  [EDetailButtonType.DESTINATION]: ECalloutActionId.SET_DESTINATION,
};

type TProps = {
  poiJson: Nullable<TPoiDataJson>;
  buttonDisable: boolean;
  onUpdateInfo?: (params: {poiDataList: TPoiDataJson[]}) => void;
};

const PoiCalloutPopupButtons = ({poiJson, onUpdateInfo, buttonDisable}: TProps) => {
  const ableToRun = useThrottle();
  const {sendClickLogWithMapView} = useLogger();
  const {reqMode, extra, reqType, moveToSelectDestinationAction} = useMoveToTarget();

  const buttons = useMemo(
    () => getCalloutActionButtons({reqMode, extra, reqType}),
    [extra, reqMode, reqType]
  );

  const handleClickShare = useCallback(() => {
    sendClickLogWithMapView(ECalloutActionId.SHARE, {pkey: poiJson?.pkey});
  }, [sendClickLogWithMapView, poiJson]);

  const handleClickFindRoute = useCallback(
    (actionType) => {
      if (ableToRun() && poiJson) {
        const actionId = BUTTON_ACTION_ID[actionType];

        if (actionId) {
          sendClickLogWithMapView(ECalloutActionId.SET_START, {
            pkey: poiJson.pkey,
          });
        }

        if (actionType === EDetailButtonType.DEPARTURE) {
          TMapSender.setDepartureOfRoute(poiJson, reqMode || EAppRequestMode.MAIN);
        } else if (actionType === EDetailButtonType.VIA) {
          TMapSender.setViaOfRoute(poiJson);
        } else if (actionType === EDetailButtonType.DESTINATION) {
          TMapSender.setDestinationOfRoute(poiJson, reqMode || EAppRequestMode.MAIN);
        } else {
          moveToSelectDestinationAction(poiJson);
        }
      }
    },
    [ableToRun, poiJson, sendClickLogWithMapView, reqMode, moveToSelectDestinationAction]
  );

  return (
    <div className={s.wrap}>
      <div className={s.left_buttons}>
        <FavoriteButton
          isDisabled={buttonDisable}
          className={s.favorite}
          poiJson={poiJson}
          onUpdateInfo={onUpdateInfo}
        />
        <ShareButton disabled={buttonDisable} onClick={handleClickShare} poiDataJson={poiJson} />
      </div>

      <div className={s.right_buttons}>
        {buttons.map((actionType, idx) => (
          <Fragment key={actionType}>
            {idx > 0 && <IcoLineBetween width={18} height={18} color="gray200" />}
            <button
              disabled={buttonDisable}
              className={s.action_button}
              onClick={() => handleClickFindRoute(actionType)}
            >
              {BUTTON_TYPE_LABEL[actionType]}
            </button>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default PoiCalloutPopupButtons;
