import {ReactNode, useCallback, useEffect, useRef} from 'react';
import Vsm from '@vsm/vsm';

import {useVSMInterfaceConsumer} from 'context/VSMInterfaceContext';
import {EMarkerType, TLonLat} from 'types/Map';
import {ImageMarker} from 'components/ImageMarker';

type TProps = {
  offset?: {
    x: number;
    y: number;
  };
  markerImage?: ReactNode;
  onInit?: (position: TLonLat) => void;
};

const MapCenterMarker = ({
  onInit,
  offset,
  markerImage = <ImageMarker type={EMarkerType.ACTIVE} />,
}: TProps) => {
  const {map, camera} = useVSMInterfaceConsumer();
  const refMarker = useRef<HTMLDivElement>(null);
  const marker = useRef<Vsm.Marker>();

  const repositionCenter = useCallback(() => {
    if (camera) {
      marker.current?.setLngLat(camera.getCenter());
    }
  }, [camera]);

  useEffect(() => {
    if (refMarker.current && map && !marker.current) {
      const lngLat = camera?.getCenter();

      if (!lngLat) {
        return;
      }

      marker.current = new Vsm.Marker({
        element: refMarker.current,
        map,
        lngLat,
        anchor: 'bottom',
        ...(offset && {offset}),
      });
      lngLat && onInit?.({lat: lngLat.lat, lon: lngLat.lng});
    }

    map?.on(Vsm.Map.EventNames.Move, repositionCenter);

    return () => {
      map?.off(Vsm.Map.EventNames.Move, repositionCenter);
    };
  }, [map, onInit, repositionCenter]);

  useEffect(() => {
    offset && marker.current?.setOffset(offset);
  }, [offset?.x, offset?.y]);

  useEffect(() => {
    return () => {
      marker.current?.destroy();
    };
  }, []);

  return (
    <div style={{display: 'none'}}>
      <div ref={refMarker} className="vsm-marker" data-type="CENTER_POINT" aria-hidden="true">
        {markerImage}
      </div>
    </div>
  );
};

export default MapCenterMarker;
