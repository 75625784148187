import {useCallback, useEffect, useMemo, useState} from 'react';
import InView from 'react-intersection-observer';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';
import DiscoverySection from './DiscoverySection';
import {TDiscoveryItem} from '../../../ducks/tplacehome/types';
import classNames from 'classnames';
import DiscoveryRowPoiItem from './DiscoveryRowPoiItem';

import s from '../../../styles/components/tplacehome/TPlaceDiscovery.module.scss';

enum ETabId {
  RESTAURANT,
  CAFE,
}

type TTab = {
  id: ETabId;
  label: string;
};

const TABS: TTab[] = [
  {
    id: ETabId.RESTAURANT,
    label: '음식점',
  },
  {
    id: ETabId.CAFE,
    label: '카페',
  },
];

const DiscoveryDelicious = () => {
  const {tplacehome, userInfo} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const [data, setData] = useState<TDiscoveryItem[]>([]);
  const [currentTabId, setCurrentTabId] = useState(ETabId.RESTAURANT);

  const poiList = useMemo(() => tplacehome.discoveryData.result.data.deliciousData, [tplacehome]);
  const {checkIsFavorite, getFavoriteGroup} = useFavorite({list: data || []});

  const handleClickTab = useCallback(
    (id: ETabId) => {
      placehomeHook.sendEventDiscovery(
        id === ETabId.RESTAURANT ? 'tap.same_taste_food' : 'tap.same_taste_cafe'
      );
      setCurrentTabId(id);
    },
    [placehomeHook]
  );

  useEffect(() => {
    setData(currentTabId === ETabId.RESTAURANT ? poiList.restaurants : poiList.cafes);
  }, [currentTabId, poiList]);

  useEffect(() => {
    getFavoriteGroup(data);
  }, [currentTabId]);

  return (
    <DiscoverySection
      mainTitle={'음식점, 카페'}
      subTitle={'내 취향과 비슷한 사람들에게 인기 있는'}
      pb={14}
    >
      <InView
        onChange={(isView) => isView && placehomeHook.sendEventDiscovery('view.same_taste_poi')}
      >
        <ul className={s.tab}>
          {TABS.map((tab) => {
            return (
              <li key={tab.id}>
                <button
                  type="button"
                  className={classNames(s.item, {[s.active]: tab.id === currentTabId})}
                  onClick={() => handleClickTab(tab.id)}
                >
                  {tab.label}
                </button>
              </li>
            );
          })}
        </ul>
        <ul className={s.row_list}>
          {data.map((item, index) => {
            return (
              <li key={`${item.poiId}_${index}`} className={s.cell}>
                <DiscoveryRowPoiItem
                  data={item}
                  isFavorite={checkIsFavorite(item)}
                  onClickDetail={() =>
                    placehomeHook.sendEventDiscovery('tap.same_taste_poi', {
                      euk: userInfo.euk,
                      pkey: item.pkey,
                      tab_filter: currentTabId === ETabId.RESTAURANT ? 'FUDALL' : 'CAFEALL',
                    })
                  }
                />
              </li>
            );
          })}
        </ul>
      </InView>
    </DiscoverySection>
  );
};

export default DiscoveryDelicious;
