import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {TPoiDataJson} from '@lcc/tmap-inapp';
import {ECalloutActionId} from 'constant/Log';
import {useAppSelector} from 'ducks/hooks';
import useThrottle from 'hooks/useThrottle';
import useLogger from 'hooks/useLogger';
import useAppScheme from 'hooks/useAppScheme';
import useUserPublicTransData from 'hooks/useUserPublicTransData';
import useFavorite from 'hooks/useFavorite';

import {ReactComponent as IconFavorite} from 'resource/images/@tmds_basic/ico_star.svg';
import {ReactComponent as IconFavoriteOn} from 'resource/images/@tmds_solid/ico_star_solid.svg';

import s from 'styles/components/FavoriteButton.module.scss';

type TProps = {
  isDisabled: boolean;
  className: string;
  poiJson: TPoiDataJson | null;
  onUpdateInfo?: (params: {poiDataList: TPoiDataJson[]}) => void;
};

const FavoriteButton = ({isDisabled, poiJson, onUpdateInfo, className}: TProps) => {
  const ableToRun = useThrottle();
  const {sendClickLogWithMapView, sendClickLog} = useLogger();

  const {calloutInfo, isUserDataLoaded, resumeKey} = useAppSelector((state) => ({
    calloutInfo: state.userInteraction.calloutInfo || state.userInteraction.pickOnMapCalloutInfo,
    isUserDataLoaded: state.userInfo.personalPlace.loaded,
    resumeKey: state.userInteraction.resumeKey,
  }));

  const [showFavorite, setShowFavorite] = useState(false);
  const {init: fetchUserPublicTransData} = useUserPublicTransData();
  const {isFavoriteItem} = useFavorite();
  const inApp = useAppScheme();

  const isPublicTransPoi = calloutInfo?.stationSktId && calloutInfo?.publicTransportType;

  const handleClick = useCallback(() => {
    if (!ableToRun() || !calloutInfo || !poiJson) {
      return;
    }

    inApp
      .toggleSearchFavorite({
        ...poiJson,
        ...(isPublicTransPoi && {
          stationId: calloutInfo.stationSktId,
          publicTransportType: calloutInfo.publicTransportType,
        }),
      })
      .then(({isOn, isNewVersion}) => {
        setShowFavorite(isOn as boolean);
        isFavoriteItem(poiJson).then(({poiDataList}) => {
          onUpdateInfo?.({poiDataList});
        });

        const defaultLogParam = {
          poi_id: poiJson.poiId,
          pkey: poiJson.pkey,
          type: 'map',
        };

        if (isNewVersion) {
          sendClickLog('tap.callout_save', {...defaultLogParam, index: 2});
        } else {
          // 신규 (24.11 저장)
          sendClickLog('tap.callout_save', {...defaultLogParam, index: isOn ? 0 : 1});
          // 레거시
          sendClickLogWithMapView(
            isOn ? ECalloutActionId.ADD_FAVORITE : ECalloutActionId.DELETE_FAVORITE,
            {pkey: poiJson.pkey}
          );
        }
      });
  }, [
    ableToRun,
    calloutInfo,
    poiJson,
    sendClickLog,
    sendClickLogWithMapView,
    isPublicTransPoi,
    inApp,
    onUpdateInfo,
    isFavoriteItem,
  ]);

  useEffect(() => {
    // 상세, 다른 탭에서 좋아요 누르고 왔을 때
    if (poiJson && isUserDataLoaded) {
      isFavoriteItem(poiJson).then(({result: isFavorite}) => {
        setShowFavorite(isFavorite);
      });
    }
  }, [poiJson, resumeKey, isUserDataLoaded, isFavoriteItem]);

  useEffect(() => {
    if (isUserDataLoaded && isPublicTransPoi) {
      fetchUserPublicTransData();
    }
  }, [isUserDataLoaded]);

  return (
    <button
      data-on={showFavorite}
      disabled={isDisabled}
      className={classNames(s.button, className)}
      onClick={handleClick}
    >
      {showFavorite ? <IconFavoriteOn /> : <IconFavorite />}
    </button>
  );
};

export default FavoriteButton;
