import {MouseEventHandler, useCallback} from 'react';
import InView from 'react-intersection-observer';
import {useAppSelector} from 'ducks/hooks';
import classNames from 'classnames';
import {EDetailButtonType} from 'types/App';
import {TPoiData, MAX_CATEGORY_NAME} from './SearchRecommendPoiItem';
import {BUSINESS_HOUR_LABEL} from 'utils/special';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';
import {IcoStar} from 'components/@tmds/icons/IcoStar';
import {IcoRouteTurnFilled} from 'components/@tmds/icons/IcoRouteTurnFilled';
import {ItemTag} from 'components/ItemTag';
import ImageLoader from 'components/ImageLoader';
import {AD_SOURCE_MAP, EAdCode} from 'constant/Ads';

import {useParsePoiData} from 'hooks/useParsePoiData';
import useLogger from 'hooks/useLogger';
import {getLocalAdLogs} from 'hooks/useTMapAds';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import {useOnce} from 'hooks/useOnce';

import s from 'styles/components/search/SearchPoiAdItem.module.scss';

type TProps = {
  poiData: TPoiData;
  onClickItem?: (e) => void;
  onClickFavorite?: (e) => void;
  onClickRouteButton?: MouseEventHandler;
  onShow?: () => void;
  hasFavorite?: boolean;
  isFavorite?: boolean;
};

const SearchPoiAdItem = ({
  poiData,
  onClickItem,
  onClickFavorite,
  onClickRouteButton,
  onShow,
  hasFavorite,
  isFavorite,
}: TProps) => {
  const {searchSessionKey} = useAppSelector((state) => ({
    searchSessionKey: state.userInfo.searchSessionKey,
  }));
  const {distance, imageInfo: images, headingScore, tags, adCopy} = useParsePoiData(poiData);
  const businessHoursInfo =
    poiData.special?.businessHourStatus && BUSINESS_HOUR_LABEL[poiData.special.businessHourStatus];
  const [label, textColor] = businessHoursInfo || [];
  const {sendClickLog} = useLogger();
  const {queries} = useParseQueryLocation();
  const {click} = getLocalAdLogs(poiData.special?.advertiseInfo);

  const handleClickItem = useCallback(
    (e) => {
      onClickItem?.(e);

      sendClickLog('tap.ad', {
        index: EAdCode.SEARCH_RESULT_POI,
        ad_source: AD_SOURCE_MAP.INTERNAL,
        url: click,
        search_session_id: searchSessionKey,
        search_query: queries.searchQuery,
        search_recommend: hasFavorite ? true : false,
        type: 'poi',
      });
    },
    [sendClickLog, searchSessionKey, queries.searchQuery, onClickItem, hasFavorite, click]
  );

  const handleClickFavorite = useCallback(
    (e) => {
      onClickFavorite?.(e);

      sendClickLog('tap.ad', {
        index: EAdCode.SEARCH_RESULT_POI,
        ad_source: AD_SOURCE_MAP.INTERNAL,
        url: click,
        search_session_id: searchSessionKey,
        search_query: queries.searchQuery,
        search_recommend: hasFavorite ? true : false,
        type: 'save',
      });
    },
    [sendClickLog, searchSessionKey, queries.searchQuery, onClickFavorite, hasFavorite, click]
  );

  const handleClickRoute = useCallback(
    (e) => {
      onClickRouteButton?.(e);

      sendClickLog('tap.ad', {
        index: EAdCode.SEARCH_RESULT_POI,
        ad_source: AD_SOURCE_MAP.INTERNAL,
        url: click,
        search_session_id: searchSessionKey,
        search_query: queries.searchQuery,
        search_recommend: hasFavorite ? true : false,
        type: 'direction',
      });
    },
    [sendClickLog, searchSessionKey, queries.searchQuery, onClickRouteButton, hasFavorite, click]
  );

  const handleAdShow = useCallback(() => {
    const {vimp} = getLocalAdLogs(poiData.special?.advertiseInfo);

    onShow && onShow();

    sendClickLog('view.ad', {
      index: EAdCode.SEARCH_RESULT_POI,
      ad_source: AD_SOURCE_MAP.INTERNAL,
      url: vimp,
      search_session_id: searchSessionKey,
      search_query: queries.searchQuery,
      search_recommend: hasFavorite ? true : false,
    });
  }, [
    onShow,
    poiData.special?.advertiseInfo,
    sendClickLog,
    searchSessionKey,
    queries.searchQuery,
    hasFavorite,
  ]);

  useOnce(!!poiData.special?.advertiseInfo.isPoiAdvertiseYn, () => {
    handleAdShow();
  });

  return (
    <InView
      onChange={(inView) => {
        if (inView) {
          handleAdShow();
        }
      }}
    >
      <div className={s.wrap} onClick={handleClickItem}>
        <div className={classNames(s.title_wrap, s.local_ad)}>
          <div className={s.text_wrap}>
            <div className={s.title_content}>
              <span className={s.ad_tag}>AD</span>
              <span className={s.title} data-style-node="title">
                {poiData.listName}
              </span>
            </div>

            <span className={s.category} data-style-node="category">
              {poiData.categoryName?.slice(0, MAX_CATEGORY_NAME)}
            </span>
          </div>
          {hasFavorite ? (
            <div className={s.favorite} onClick={handleClickFavorite}>
              {isFavorite ? (
                <IcoStarFilled width={24} height={24} color="yellow500" />
              ) : (
                <IcoStar width={24} height={24} color="gray300" />
              )}
            </div>
          ) : (
            <button
              className={s.button_wrap}
              data-action={EDetailButtonType.DESTINATION}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleClickRoute(e);
              }}
              aria-label="길찾기"
            >
              <span className={s.route}>
                <IcoRouteTurnFilled width={22} height={22} color="wbWhite" />
              </span>
            </button>
          )}
        </div>

        <div className={s.info_wrap}>
          {!!businessHoursInfo && (
            <>
              <p data-color={textColor}>{label}</p>
              <IcoLineBetween color="gray200" width={16} height={16} />
            </>
          )}
          <p className={s.distance}>{distance}</p>
          <IcoLineBetween color="gray200" width={16} height={16} />
          <p className={s.address}>{poiData?.addressNameDepth2}</p>
          {!!headingScore && <IcoLineBetween color="gray200" width={16} height={16} />}
          {!!headingScore && <span className={s.count}>{headingScore}대 가는중</span>}
        </div>

        {!!adCopy && <div className={s.ad_info}>{adCopy}</div>}

        <div
          className={classNames(s.special_action, {
            [s.is_hide]: tags.length < 1 && images.length < 1,
          })}
        >
          {tags.length > 0 && (
            <p className={s.tag_list}>
              {tags.map((t, tIdx) => (
                <span className={s.tag} key={tIdx}>
                  <ItemTag {...t} />
                </span>
              ))}
            </p>
          )}
          <div className={classNames(s.image_wrap, {[s.hide]: images.length < 1})}>
            <div className={s.image_list}>
              {images.map((image, imgIdx) => {
                return (
                  <div className={s.image_container} key={imgIdx}>
                    <div className={s.image} key={imgIdx}>
                      <ImageLoader
                        toggleClass={s.fade_in}
                        src={image.url || image.url2}
                        alt={`${poiData.listName} 이미지`}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
};

export default SearchPoiAdItem;
