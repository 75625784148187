import {PropsWithChildren} from 'react';
import classNames from 'classnames';
import ImageLoader from './ImageLoader';

import s from '../styles/components/Thumbnail.module.scss';

type TThumbProps = {
  imgSrc?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  alt?: string;
};

type TProps = PropsWithChildren<TThumbProps>;

const Thumbnail = (props: TProps) => {
  const {imgSrc, width, height, alt, className, children} = props;

  return (
    <div className={classNames(s.wrap, className)} style={{width, height}}>
      <ImageLoader
        src={imgSrc || ''}
        toggleClass={s.fade_in}
        alt={alt || ''}
        showDefaultOnError={true}
      />
      {children}
    </div>
  );
};

export default Thumbnail;
